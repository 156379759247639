import Label from './Label';
import './style/Banner.css';
import React, { useEffect, useState } from 'react';
import { getImgUrl } from '../../util/ImageSource';
import { formatToCurrency, formatPercentWithCommas, displayPriceForProduct } from '../../util/Calculate';
import CaretRight from '../../media/caret-right@3x.png';
import ChipRounded from './ChipRounded';
import Button from './Button';

import { } from '../../redux/temporarySlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LinkButtonWrapper from './LinkButtonWrapper';

const Banner = ({ handleViewProduct, hrefForViewProduct }) => {
    const featuredProducts = useSelector((state) => state.temporary?.featuredProducts);
    const [featured, setFeatured] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (featuredProducts?.length > 0) {
            const mastechItem = featuredProducts.find(product => product.marketMpn === "MS6522B Infrared Thermometer");
            const kpsOne = featuredProducts.find(product => product.marketMpn === "MT490 Digital Environmental Multimeter");
            const kpsTwo = featuredProducts.find(product => product.marketMpn === "MT700 Digital Multimeter");
            setFeatured([mastechItem, kpsOne, kpsTwo]);
        }
    }, [featuredProducts]);

    return (
        <div className="shop-max-width flex vertical relative v-gap-m no-pad-bo width-100">
            <div className="banner flex vertical relative pad-m">
                <div className="yellow-bg flex horizontal space-between flex-1 h-gap-xl round-m no-round-bot pad-l">
                    <div className="flex vertical">
                        <Label header='h2' customId="banner-title" content="KPS & MASTECH SALE!" mono={true} color="black" semiBold={true} size="xl" />
                        <Label header='h3' customId="banner-title-subtitle" content="Save up to 60% on all test equipment lots." displayFont={true} semiBold={true} size="s" />
                    </div>
                    <LinkButtonWrapper label='Shop All Test Equipment' href='/categories/test-equipment'>
                        <Button label="Shop All Test Equipment" color="white" onBrand={true} small={true} onClick={() => navigate('/categories/test-equipment')} textColor='black' />
                    </LinkButtonWrapper>
                </div>
                {/* <img src={KpsLogo} alt="KPS Logo" className="banner-logo" /> */}
                <div className="flex yellow-bg round-m no-round-top pad-l horizontal flex-1 h-gap-xl">
                    {featured?.length > 0 && featured.map((product, index) => (
                        <div key={index + product.marketMpn} className={`banner-item round-s flex horizontal flex-1 relative ${index !== 0 ? "mobile-hide" : ""}`}>
                            <img src={getImgUrl(product)} alt={product.marketManufacturer} className={`banner-image saturate no-pointer`} />
                            <div className="banner-item-content flex vertical justify-center flex-1 v-gap-xs">
                                <div className="flex vertical v-gap-xxs">
                                    <Label customId={"banner-text-subtitle"} content={product.marketManufacturer} mono={true} semiBold={true} size="s" />
                                    <Label customId="banner-text" content={product.marketMpn} mono={true} semiBold={true} color='black' size="m" classes="fix-stroke" />
                                </div>
                                <div className="flex vertical v-gap-xs">
                                    <div className="flex horizontal align-center h-gap-xs">
                                        <Label content={formatToCurrency(displayPriceForProduct(product))} mono={true} size="display" />
                                        <LinkButtonWrapper label='View Product' href={hrefForViewProduct(product)}>
                                            <Button icon={CaretRight} onClick={() => handleViewProduct(product)} iconSize={12} iconColor="black" onBrand={true} />
                                        </LinkButtonWrapper>
                                    </div>
                                    <Label content={`Per lot of ${product?.stock[0]?.lot_size}`} semiBold={true} displayFont={true} size="s" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Banner;