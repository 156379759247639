export const formatToCurrency = (value) => {
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};

export const stockWithLowestPrice = (product) => {
    return product?.stock?.reduce((prev, current) => {
        return (prev.price < current.price) ? prev : current;
    });
};

export const availableQuantityForStock = (stock) => {
    return Math.floor(stock?.quantity / stock?.lot_size);
};

export const displayLotSizeForProduct = (product) => {
    return `${(product?.stock[0]?.lot_size > 1 ? `of ${product?.stock[0]?.lot_size}` : "")}`;
};

export const availableQuantityForProduct = (product) => {
    return product.stock.reduce((acc, stock) => acc + availableQuantityForStock(stock), 0);
};

export const quantityTypeSingularForStock = (stock) => {
    return stock?.lot_size > 1 ? "lot" : stock?.quantity_type;
};

export function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
};

const quantityType = (stock, quantity) => {
    if (stock?.lot_size > 1 && quantity > 1) {
        return "lots";
    }
    if (stock?.lot_size > 1) {
        return "lot";
    }
    if (stock?.quantity_type_plural && stock?.quantity_type) {
        return quantity > 1 ? stock?.quantity_type_plural : stock?.quantity_type;
    }

    return (stock?.quantity_type?.toLowerCase() === "unit" && quantity > 1) ? "units" : 
        stock?.quantity_type?.toLowerCase();
};

export const quantityTypeForProduct = (product) => {
    const stock = stockWithLowestPrice(product);
    const availableQuantity = availableQuantityForProduct(product);
    return quantityType(stock, availableQuantity);
};

export const quantityTypeForStock = (stock) => {
    return quantityType(stock, availableQuantityForStock(stock));
};

export const defaultPriceForProduct = (product) => {
    let originalPrice = stockWithLowestPrice(product).price;
    let marketPrice = product.lowestMarketPrice;

    let defaultPrice = originalPrice;

    if (marketPrice > 0 && (marketPrice * 0.8 > originalPrice)) {
        defaultPrice = marketPrice * 0.8;
    }

    return defaultPrice;
};


export const displayPriceForProduct = (product) => {
    return stockWithLowestPrice(product)?.lot_size * defaultPriceForProduct(product);
};

export const priceForStock = (product, stock) => {
    const lotSize = stock?.lot_size || 1;
    return defaultPriceForProduct(product) * lotSize;
};

export const offerDiscountForQuantity = (offer, quantity) => {
    const originalPrice = offer.originalPrice;
    const availableQuantity = availableQuantityForStock(offer);
    const offerQuantity = offer.offerQuantity;

    offer.offerQuantity = (quantity === "all" && (offerQuantity === availableQuantity)) ? 0 :((Number(quantity) > availableQuantity) || quantity === "all") ? availableQuantity : quantity;

    const newOfferQuantity = offer.offerQuantity;

    if (originalPrice === 0) {
        return offer;
    }

    // TODO: Handle availablequantity 0 for infinity and beyond
    if ((newOfferQuantity / availableQuantity < 0.25) && newOfferQuantity > 1) {
        // <25% volume and over 1 quantity = 5% discount
        offer.approvedPrice = originalPrice * 0.95;
        offer.offerPrice = originalPrice * 0.95;
        offer.discount = 5;
    } else if ((newOfferQuantity / availableQuantity <= 0.5) && newOfferQuantity > 1) {
        // 25-50% volume and over 1 quantity = 10% discount
        offer.approvedPrice = originalPrice * 0.90;
        offer.offerPrice = originalPrice * 0.90;
        offer.discount = 10;
    } else if ((newOfferQuantity / availableQuantity <= 0.75) && newOfferQuantity > 1) {
        // 50-75% volume and over 1 quantity = 15% discount
        offer.approvedPrice = originalPrice * 0.85;
        offer.offerPrice = originalPrice * 0.85;
        offer.discount = 15;
    } else if ((newOfferQuantity / availableQuantity > 0.75) && newOfferQuantity > 1) {
        // >75% volume and over 1 quantity = 20% discount
        offer.approvedPrice = originalPrice * 0.80;
        offer.offerPrice = originalPrice * 0.80;
        offer.discount = 20;
    } else {
        offer.approvedPrice = originalPrice;
        offer.offerPrice = originalPrice;
        offer.discount = 0;
    }

    return offer;
};

export const formatNumberWithCommas = (number) => {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPercentWithCommas = (number, decimals = 0) => {
    if (isNaN(number)) {
       return "N/A";
    }
    
    // Convert to percentage and format with specified decimals.
    const percentage = (number * 100).toFixed(decimals);
    
    // Use regex to add commas.
    const withCommas = percentage.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    // Append the percent symbol.
    return `${withCommas}%`;
};

export const formatToCurrencyExpanded = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 5, maximumFractionDigits: 5 }).format(value);
};

const materialCostPerPound = {
    'stainless-steel': 0.52,
    'aluminum': 0.44,
    'steel': 0.16,
    'copper': 3.1,
};

export const getCustomValueForRow = (row) => {
    const weight = row['manual-weight'] !== null ? row['manual-weight'] : 0;
    return weight * (row['manual-material'] !== null ? materialCostPerPound[row['manual-material']] : 0);
};

export const getValueForRow = (row, selectedCostColumn, selectedQtyColumn, costType, urgency) => {
    const selectedCostValue = row[selectedCostColumn];
    const isCustom = row['manual-type'] === 'custom';

    let valueIfSold = 0;
    if (isCustom) {
        valueIfSold = getCustomValueForRow(row);
    } else if (selectedCostValue) {
        valueIfSold = Number(selectedCostValue?.toString().replace(/[^0-9.]+/g, ''));

        if (costType === 'unit') {
            valueIfSold *= Number(row[selectedQtyColumn]?.toString().replace(/[^0-9.]+/g, '')) || 1;
        }
    }

    const multiple = urgency === 2 ? 1 : urgency === 1 ? 0.82 : 0.64;
    return valueIfSold * multiple;
};

export const getValueForRowFormatted = (row, selectedCostColumn, selectedQtyColumn, costType, urgency) => {
    return formatToCurrency(getValueForRow(row, selectedCostColumn, selectedQtyColumn, costType, urgency));
};