import './style/Image.css';
import React from 'react';

const Image = ({ customId = null, source, altTag, width = "medium", fullWidth = false, overlayContent, whiteBg = true, fullHeight = false, classes="", onClick = null, noPad = false}) => {
    return (
        <div id={customId} className={`image-wrapper flex relative ${noPad ? 'no-pad' : !fullHeight && width !== "small" ? "pad-xl" : width === "small" ? "pad-m" : "height-100"} ${whiteBg ? "image-white-bg" : ""} ${classes} ${onClick ? "clickable" : ""}`} onClick={onClick}>
            <img src={source} alt={altTag} className={`image ${fullWidth ? "width-100" : !fullHeight ? width : ""} ${fullHeight ? "height-100" : ""}`} />
            {overlayContent && (
                <div className="image-overlay">
                    {overlayContent}
                </div>
            )}
        </div>
    );
};

export default Image;