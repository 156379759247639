
import './style/ConfirmOfferModal.css';
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from './Button';
import Label from './Label';
import LabelInput from './LabelInput';
import Modal from './Modal';
import SummaryFooter from './SummaryFooter';
import ReCAPTCHA from 'react-google-recaptcha';
import Divider from './Divider';
import Action from './Action';
import { getImgUrl } from '../../util/ImageSource';

import Close from '../../media/close@3x.png';
import CartFill from '../../media/cart_fill_24.png';
import PlaneFill from '../../media/plane_fill_24.png';
import OfferSuccess from '../../media/offer-success.png';

import { setShowOfferConfirmation } from '../../redux/temporarySlice';

const ConfirmOfferModal = (
    {
        showConfirmModal,
        setShowConfirmModal,
        currentProduct = null,
        offerSummary,
        handleSubmitOffer,
        setCaptchaResponse, // TODO: Possible improvement to call submit with these attributes rather than pass down setters
        setCaptchaComplete,
        contactInfo,
        setContactInfo
    }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { pathname } = useLocation();

    const sitekey = window.location?.href?.includes('localhost') ? '6LeRb4AqAAAAAMi0AgNFSMFxyw9bo5q2wePZ9p3u' : '6LeVWn8qAAAAAHHCHAzhrmTQjuadBu9fzePxRzyo';
    const submittingOffer = useSelector((state) => state.temporary?.submittingOffer);
    const showOfferConfirmation = useSelector((state) => state.temporary?.showOfferConfirmation);

    const onCart = useMemo(() => pathname === "/cart", [pathname]);

    const [continueButtonLoading, setContinueButtonLoading] = useState(false);
    const [errorsVisible, setErrorsVisible] = useState(false);
    const [errorShake, setErrorShake] = useState(false);
    const [formCatpchaComplete, setFormCaptchaComplete] = useState(false);


    const handleFullNameChange = (e) => {
        const existingContactInfo = { ...contactInfo };
        existingContactInfo.fullName = e.target.value;
        setContactInfo(existingContactInfo);
    };

    const handleEmailChange = (e) => {
        const existingContactInfo = { ...contactInfo };
        existingContactInfo.email = e.target.value.replace(/\s/g, '');;
        setContactInfo(existingContactInfo);
    };

    const handleCompanyChange = (e) => {
        const existingContactInfo = { ...contactInfo };
        existingContactInfo.company = e.target.value;
        setContactInfo(existingContactInfo);
    };

    const handleCaptchaChange = (value) => {
        setFormCaptchaComplete(value);
        if (value) {
            setCaptchaComplete(true);
            setCaptchaResponse(value);
        }
    };

    const handleFormSubmit = () => {
        if (contactInfo?.fullName === '' ||
            contactInfo?.email === '' ||
            !validateEmail(contactInfo?.email) ||
            contactInfo?.company === '' ||
            !formCatpchaComplete) {
            setErrorsVisible(true);
            setErrorShake(true);
            setTimeout(() => {
                setErrorShake(false);
            }, 1000);
            return;
        } else {
            setErrorsVisible(false);
            handleSubmitOffer();
        }
    }
    
    const validateEmail = (email) => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g.test(email);
    };
    

    const handleOpenTermsOfUse = () => {
        window.open("https://www.trymaterialize.com/terms-of-use", "_blank");
    };

    const handleViewCart = () => {
        navigate('/cart');
    };

    const handleContinue = () => {
        setContinueButtonLoading(true);
        setTimeout(() => {
            setShowConfirmModal(false)
            dispatch(setShowOfferConfirmation(false));
            setContinueButtonLoading(false);
        }, 700);
    };

    const handleCloseConfirmationModal = () => {
        setShowConfirmModal(false)
        dispatch(setShowOfferConfirmation(false));
    };
    
    return (
        <Modal showModal={showConfirmModal} setShowModal={setShowConfirmModal} content={(
            <div id="modal-shadow" className={`small-modal flex vertical pad-xl v-gap-m ${errorShake ? 'error-shake':''}`} onClick={null}>
                {!showOfferConfirmation && (
                    <div className="flex horizontal flex-1 h-gap-xl align-center space-between">
                        <Label content="Confirm Offer" displayFont={true} size="xl" semiBold={true} />
                        <Button icon={Close} iconColor="#6f7aa5" iconSize={18} onClick={handleCloseConfirmationModal} />
                    </div>
                )}
                {!showOfferConfirmation ? (
                    <div className="flex vertical flex-1 h-gap-xl v-gap-l">
                        {currentProduct && (
                            <div className="flex vertical flex-2 v-gap-m">
                                <div className="flex horizontal flex-1 h-gap-xl">
                                    <img src={getImgUrl(currentProduct)} alt={`${currentProduct?.marketManufacturer} - ${currentProduct?.marketMpn}`} style={{width: "100px", height: "auto", objectFit: "contain", margin: "0 auto"}} />
                                    <div className="flex vertical flex-1 justify-center">
                                        <Label content={currentProduct?.marketManufacturer} semiBold={true} mono={true} size="s" secondary={true} uppercase={true} />
                                        <Label content={currentProduct?.marketMpn} mono={true} size="l" uppercase={true} />
                                        <Label content={currentProduct?.description.slice(0, 80) + "..."} size="s" mono={true} secondary={true} />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="flex vertical v-gap-s">
                            <Label content="Contact Information" displayFont={true} size="l" semiBold={true} />
                            <div className="flex vertical grey-bg pad-s round-s v-gap-s">
                                <LabelInput
                                    label="Full Name"
                                    horizontal={true}
                                    onChange={handleFullNameChange}
                                    classes={errorsVisible && contactInfo?.fullName === '' ? "error" : ""}
                                    labelStyle={{flexBasis: "124px"}}
                                    inputStyle={{flexGrow: "1"}} />
                                <LabelInput
                                    label="Email"
                                    horizontal={true}
                                    onChange={handleEmailChange}
                                    value={contactInfo.email}
                                    type="email"
                                    disallowSpaces={true}
                                    classes={errorsVisible && (contactInfo?.email === '' || !validateEmail(contactInfo?.email)) ? "error" : ""}
                                    labelStyle={{flexBasis: "124px"}}
                                    inputStyle={{flexGrow: "1"}} />
                                <LabelInput
                                    label="Company"
                                    horizontal={true}
                                    onChange={handleCompanyChange}
                                    classes={errorsVisible && contactInfo?.company === '' ? "error" : ""}
                                    labelStyle={{flexBasis: "124px"}}
                                    inputStyle={{flexGrow: "1"}} />
                            </div>
                        </div>
                        <div className="flex vertical v-gap-s">
                            <Label content="Offer Summary" displayFont={true} size="l" semiBold={true} />
                            <div className="grey-bg pad-s round-s">
                                <SummaryFooter summary={offerSummary} />
                            </div>
                        </div>
                        <div className={`flex horizontal flex-wrap gap-s align-start ${errorsVisible && !formCatpchaComplete ? "error" : ""}`}>
                            <ReCAPTCHA
                                    sitekey={sitekey} 
                                    onChange={handleCaptchaChange} />
                            <div className="flex vertical align-stretch flex-1 v-gap-s space-between">
                                {!onCart && <Button
                                    label="View Cart"
                                    onClick={handleViewCart}
                                    secondary={true}
                                    large={true}
                                    icon={CartFill}
                                    iconSize={14}
                                    iconColor="black"
                                    classes="space-between h-gap-m flex-1"
                                    style={{padding: "9px 12px", width:'100%', flexDirection:"row-reverse"}} />}
                                <Button
                                    label="Submit Offer"
                                    onClick={handleFormSubmit}
                                    brand={true}
                                    large={true}
                                    icon={PlaneFill}
                                    loading={submittingOffer}
                                    iconSize={14}
                                    iconColor="black"
                                    classes="space-between h-gap-m flex-1"
                                    style={{padding: "9px 12px", width:'100%', flexDirection:"row-reverse"}} />
                            </div>
                        </div>
                        <div className="flex vertical v-gap-m">
                            <Divider />
                            <div className="flex horizontal h-gap-xs justify-center">
                                <Label size="s" displayFont={true} secondary={true} semiBold={true} content="By submitting an offer, you agree to our"/>
                                <Action
                                    label="Terms and Conditions."
                                    onClick={handleOpenTermsOfUse}
                                    classes="underline"
                                    capitalize={false} />
                            </div>
                        </div>
                    </div>
                 ) : (
                    <div className="flex vertical align-center v-gap-l pad-l no-pad-left no-pad-right">
                        <img src={OfferSuccess} style={{height: "64px"}} alt="Offer Success" />
                        <div className="flex vertical align-center v-gap-xs">
                            <Label content="Your offer has been received!" displayFont={true} size="xl" semiBold={true} />
                            <Label
                                content="A confirmation has been sent to your email, and our team will reach out soon if your offer is accepted."
                                displayFont={true}
                                size="m"
                                secondary={true}
                                semiBold={true}
                                center={true}
                                line="m" />
                        </div>
                        <Button
                            label="Continue"
                            onClick={handleContinue}
                            brand={true}
                            loading={continueButtonLoading}
                            large={true} />
                    </div>
                 )}
            </div>
        )}/>
    );
};

export default ConfirmOfferModal;