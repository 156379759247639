import './style/Store.css';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import StoreHeader from '../component/store/StoreHeader';
import StoreSidebar from '../component/store/StoreSidebar';
import Toast from '../component/block/Toast';
import Button from '../component/block/Button';
import CookieSettings from '../component/block/CookieSettings';
import Label from '../component/block/Label';
import StoreContent from '../component/store/StoreContent';
import LogoWhite from '../media/materialize-logo-white.png';

import LogRocket from 'logrocket';
import posthog from 'posthog-js'
import Banner from '../component/block/Banner';

import { setCart, setCookies, setScrollPosition } from '../redux/localSlice';
import { getCategories, getProducts, getManufacturers, getFeaturedProducts, getAlsoViewedProducts } from '../redux/temporarySlice';
import { Helmet } from 'react-helmet';
import { offerDiscountForQuantity } from '../util/Calculate';

const Store = () => {
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const { manufacturer, productManufacturer, productPartNumber } = useParams();
    const navigate = useNavigate();

    const local = useSelector((state) => state.local);
    const rawProducts = useSelector((state) => state.temporary?.products);
    const gettingProducts = useSelector((state) => state.temporary?.gettingProducts);
    const gettingCategories = useSelector((state) => state.temporary?.gettingCategories);
    const gettingManufacturers = useSelector((state) => state.temporary?.gettingManufacturers);
    const gettingAlsoViewedProducts = useSelector((state) => state.temporary?.gettingAlsoViewedProducts);
    const featuredProducts = useSelector((state) => state.temporary?.featuredProducts);
    const alsoViewedProducts = useSelector((state) => state.temporary?.alsoViewedProducts);
    const rawCategories = useSelector((state) => state.temporary?.categories);
    const rawManufacturers = useSelector((state) => state.temporary?.manufacturers);

    const [searchQuery, setSearchQuery] = useState({});

    const [alsoViewedProductsQuery, setAlsoViewedProductsQuery] = useState({
        query: "",
        category: "",
        manufacturer: "",
        page: 0
    });

    const [searchInputValue, setSearchInputValue] = useState('');

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showAddedToCartToast, setShowAddedToCartToast] = useState(false);

    const [showCookieModal, setShowCookieModal] = useState(false);
    const [cookieModalVisible, setCookieModalVisible] = useState(false);
    const timeoutRef = useRef(null);

    const topLevelCategoryMap = useMemo(() => {
        if (rawCategories?.length > 0) {
            const newCategoryStructure = [];
            const newCategoryMap = {};

            rawCategories.forEach((category) => {
                const categoryPathArray = category.split('/').filter(Boolean);
                let currentCategory = newCategoryStructure;

                categoryPathArray.forEach((categoryName, index) => {
                    // Use an identifier to distinguish, for example, using full path segment
                    const identifier = categoryPathArray.slice(0, index + 1).join('/');

                    if (!newCategoryMap[identifier]) {
                        newCategoryMap[identifier] = {
                            name: categoryName, 
                            children: []
                        };
                        currentCategory.push(newCategoryMap[identifier]);
                    }

                    currentCategory = newCategoryMap[identifier].children;
                });
            });

            return {
                topLevelCategories: newCategoryStructure,
                categoryMap: newCategoryMap
            };
        }
    }, [rawCategories]);

    const categories = topLevelCategoryMap?.topLevelCategories;
    const categoryMap = topLevelCategoryMap?.categoryMap;

    const currentProduct = useMemo(() => {
        if (!pathname.startsWith("/products")) {
            return null;
        }
        const pathSegments = pathname.split('/').filter(segment => segment.length > 0 && segment !== "products");

        const currentProduct = rawProducts?.find((product) => encodeURIComponent(product.marketManufacturer?.toLowerCase()) === pathSegments[0] && encodeURIComponent(product.marketMpn?.toLowerCase()) === pathSegments[1]);
        if (!currentProduct) {
            return featuredProducts?.find((product) => encodeURIComponent(product.marketManufacturer?.toLowerCase()) === pathSegments[0] && encodeURIComponent(product.marketMpn?.toLowerCase()) === pathSegments[1]);
        }
        return currentProduct;
    }, [pathname, rawProducts, featuredProducts]);
    
    const newParams = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        if (pathname === "/") {
            return {
                query: '',
                manufacturer: '',
                category: '',
                page: (parseInt(searchParams.get('page')) - 1) || 0
            };
        } else if (pathname.startsWith("/categories")) {
            return {
                query: '',
                manufacturer: '',
                category: pathname.substring(pathname.indexOf('/categories/') + '/categories/'.length),
                page: (parseInt(searchParams.get('page')) - 1) || 0
            };
        } else if (pathname === "/cart") {
            return {
                query: '',
                manufacturer: '',
                category: '',
                page: 0
            };
        } else if (pathname === "/search") {
            return {
                query: searchParams.get('query') || '',
                page: (parseInt(searchParams.get('page')) - 1) || 0,
                manufacturer: '',
                category: ''
            };
        } else if (pathname.startsWith("/manufacturers") && manufacturer) {
            return {
                query: '',
                manufacturer: pathname.split('/')?.[2] || '',
                page: (parseInt(searchParams.get('page')) - 1) || 0,
                category: ''
            };
        } else if (pathname.startsWith("/products") && productManufacturer && productPartNumber) {
            return {
                query: pathname.split('/').filter(segment => segment.length > 0 && segment !== "products").pop() || '',
                manufacturer: '',
                category: '',
                page: 0
            };
        } else {
            // Empty search... kind of a hack to support 404 loading as just an empty set search
            return {
                query: '',
                manufacturer: '',
                category: '',
                page: 0,
                limit: 0
            }
        }
    }, [search, pathname, manufacturer, productManufacturer, productPartNumber]);

    const alsoViewedSearchParams = useMemo(() => {
        if (currentProduct) {
            return {
                query: '',
                manufacturer: '',
                category: currentProduct.categoryPath?.split('/')?.[0] || '',
                page: 0
            };
        } else {
            return {
                query: '',
                manufacturer: '',
                category: '',
                page: 0
            };
        }
    }, [currentProduct]);
    
    const categoriesToShowInSidebar = useMemo(() => {
        if (pathname === "/") {
            return categories;
        } else if (pathname.startsWith("/categories")) {
            return categoryMap?.[pathname.substring(pathname.indexOf('/categories/') + '/categories/'.length)]?.children;
        }
    }, [categories, categoryMap, pathname]);

    const alsoViewed = useMemo(() => {
        if (!currentProduct) {
            return [];
        }
        return alsoViewedProducts.filter((storeProduct) => storeProduct.marketMpn !== currentProduct?.marketMpn).sort(() => 0.5 - Math.random()).slice(0, 4);
    }, [alsoViewedProducts, currentProduct]);

    const pathBreadcrumbs = useMemo(() => {
        if (pathname.startsWith("/categories")) {
            return pathname.split('/').filter(segment => segment.length > 0 && segment !== "categories");
        } else if (pathname.startsWith("/products")) {
            return pathname.split('/').filter(segment => segment.length > 0 && segment !== "products");
        } else if (pathname.startsWith("/manufacturers")) {
            return pathname.split('/').filter(segment => segment.length > 0 && segment !== "manufacturers");
        }
        return [];
    }, [pathname]);

    const showCart = pathname === "/cart";

    const is404 = useMemo(() => {
        let result = pathname !== "/"
        && !pathname.startsWith("/categories")
        && (!pathname.startsWith("/products") || !productManufacturer || !productPartNumber)
        && (!pathname.startsWith("/manufacturers") || !manufacturer)
        && pathname !== "/search"
        && pathname !== "/cart";
        
        if (!gettingCategories && rawCategories?.length && pathname.startsWith("/categories")) {
            // If we're on a category page and this category in the path doesn't exist, it's a 404
            if (!rawCategories?.includes(pathname.substring(pathname.indexOf('/categories/') + '/categories/'.length))) {
                result = true;
            }
        }

        if (!gettingManufacturers && rawManufacturers?.length && pathname.startsWith("/manufacturers")) {
            // If we're on a manufacturer page and this manufacturer in the path doesn't exist, it's a 404
            if (!rawManufacturers?.map(m => encodeURIComponent(m?.toLowerCase())).includes(pathname.split('/')?.[2])) {
                result = true;
            }
        }

        if (productManufacturer && productPartNumber && pathname.startsWith("/products")) {
            // If we're on the product page, and we either ARE going to start a new search, or we aren't and we're not searching:
            // This is a specific deviation from the above logic because searching can return 0 results, and we can't just
            // rely on the rawProducts array being empty to determine if we haven't found the product (as it starts empty)
            const shouldSearch = (JSON.stringify(newParams) !== JSON.stringify(searchQuery));
            if (!shouldSearch && !gettingProducts && !currentProduct) {
                result = true;
            }
        }

        return result;
    }, [pathname, productManufacturer, productPartNumber, manufacturer, gettingCategories, rawCategories, gettingManufacturers, rawManufacturers, newParams, searchQuery, gettingProducts, currentProduct]);

    useEffect(() => {
        if ((JSON.stringify(newParams) !== JSON.stringify(searchQuery)) && !gettingProducts) {
            setSearchQuery(newParams);
            dispatch(getProducts(newParams));
        }
    }, [dispatch, gettingProducts, newParams, rawProducts?.length, searchQuery]);

    useEffect(() => {
        if (currentProduct && (JSON.stringify(alsoViewedSearchParams) !== JSON.stringify(alsoViewedProductsQuery) || alsoViewedProducts?.length === 0) && !gettingAlsoViewedProducts) {
            setAlsoViewedProductsQuery(alsoViewedSearchParams);
            dispatch(getAlsoViewedProducts(alsoViewedSearchParams));
        }
    }, [alsoViewedProducts?.length, alsoViewedProductsQuery, alsoViewedSearchParams, currentProduct, dispatch, gettingAlsoViewedProducts]);

    useEffect(() => {
        if (rawCategories?.length === 0) {
            dispatch(getCategories());
        }
    }, [rawCategories?.length, dispatch]);

    useEffect(() => {
        if (rawManufacturers?.length === 0) {
            dispatch(getManufacturers());
        }
    }, [rawManufacturers?.length, dispatch]);

    useEffect(() => {
        if (featuredProducts?.length === 0) {
            dispatch(getFeaturedProducts());
        }
    }, [featuredProducts?.length, dispatch]);

    useEffect(() => {
        if (showSuccessToast) {
            setTimeout(() => {
                setShowSuccessToast(false);
            }, 4000);
        }
    }, [showSuccessToast]);

    useEffect(() => {
        if (showAddedToCartToast) {
            setTimeout(() => {
                setShowAddedToCartToast(false);
            }, 4000);
        }
    }, [showAddedToCartToast]);

    useEffect(() => {
        const scrollViewElement = document.getElementById("scroll-view");
        if (scrollViewElement) {
            scrollViewElement.scrollTop = currentProduct ? 0 : local.scrollPosition
        }
    }, [rawProducts, local.scrollPosition, currentProduct]);

    const isLocalhost = window.location.origin.includes('localhost') ? true : false;
    const isBotTraffic = /^Mozilla\/5\.0 \(Windows NT 10\.0; Win64; x64\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/12.*Safari\/537\.36$/.test(navigator.userAgent);
    
    const initAnalytics = () => {
        const scriptOne = document.createElement("script");
        scriptOne.src = "https://www.googletagmanager.com/gtag/js?id=G-VC0BWS1RKL";
        scriptOne.async = true;
        document.head.appendChild(scriptOne);

        const scriptTwo = document.createElement("script");
        scriptTwo.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-VC0BWS1RKL');
        `;
        document.head.appendChild(scriptTwo);

        posthog.init('phc_NGGCjf8a2ZLnMRrPhBqOI0pD9UbnTQklPmSsuGNxzEb',
            {
                api_host: 'https://us.i.posthog.com',
                person_profiles: 'always' // or 'identified_only' to not create profiles for anonymous users
            }
        );
        
        LogRocket.init('o4yyvv/materialize-shop');
    }

    const initChat = () => {
        window.plugSDK.init({
            app_id: 'DvRvStPZG9uOmNvcmU6ZHZydi11cy0xOmRldm8vMWFaRHBMS1pXVzpwbHVnX3NldHRpbmcvMV9ffHxfXzIwMjQtMTItMDMgMDU6MDI6MjEuMDgxNzM0Mzc5ICswMDAwIFVUQw==xlxendsDvRv',
            widget_alignment: "left",
            title: "Chat with us",
            primary_text_color: "#000000"
        });
    }

    if (!isLocalhost && !isBotTraffic && (local?.cookies || local?.cookies === "all" || local?.cookies === "analytics")) {
        initAnalytics();
        if (local?.cookies === "all" || local?.cookies === "analytics") {
            initChat();
        }
    }

    const storeScrollPosition = () => {
        const scrollViewElement = document.getElementById("scroll-view");
        if (scrollViewElement) {
            dispatch(setScrollPosition(scrollViewElement.scrollTop));
        }
    }

    const hrefForViewProduct = (product) => {
        return `/products/${encodeURIComponent(product.marketManufacturer?.toLowerCase())}/${encodeURIComponent(product.marketMpn?.toLowerCase())}`;
    }

    const handleViewProduct = (product) => {
        storeScrollPosition();
        navigate(hrefForViewProduct(product));
    };

    const handleCategoryChange = (category) => {
        dispatch(setScrollPosition(0));
        navigate(hrefForHandleCategoryChange(category));
    }

    const hrefForHandleCategoryChange = (category) => {
        let rootPath = (!pathname || pathname === "/") ? "" : pathname;
        if (!rootPath.startsWith("/categories")) {
            rootPath = "/categories";
        }
        return `${rootPath}/${category.name}`;
    }

    const handleCategoryChangeNew = (categoryName) => {
        if (hrefForHandleCategoryChangeNew(categoryName)) {
            dispatch(setScrollPosition(0));
            navigate(hrefForHandleCategoryChangeNew(categoryName));
        }
    };

    const hrefForHandleCategoryChangeNew = (categoryName) => {
        let rootPath = (!pathname || pathname === "/") ? "" : pathname;
        if (rootPath.startsWith('/products')) {
            // Because a category change in the product view implies visiting the manufacturer,
            // we need to strip the product text and replace it with manufacturers
            rootPath = rootPath.replaceAll('/products', '/manufacturers');
        }
        
        if (rootPath.includes(categoryName)) {
            // Find the index of the category name in the rootPath
            const categoryIndex = rootPath.indexOf(categoryName);
    
            // Strip everything after the category name
            return `${rootPath.substring(0, categoryIndex + categoryName.length)}`;
        }
        return '';
    }

    const addToCart = (product, quantity, offers) => {
        const existingCartItem = local.cart.find((cartItem) => cartItem.product.marketMpn === product.marketMpn && cartItem.product.marketManufacturer === product.marketManufacturer);
        if (existingCartItem) {
            if (!existingCartItem?.offers) {
                // old cart item
                // TODO: reformat existing cart item with new offers structure (just pick quantity to allot it to?)
                // TODO: Potentially add a migration step here to convert old cart items to new offers structure or to just flush the cart
                // if old.
                // or just remove it from cart
            }

            const newCart = local.cart.map((cartItem) => {
                if (cartItem.product.id === product.id) {
                    let newOffers = cartItem.offers;
                    for (let offer of offers) {
                        let existingOffer = newOffers.find((existingOffer) => existingOffer.stock_id === offer.stock_id);
                        if (existingOffer) {
                            let newQuantity = existingOffer.offerQuantity + offer.offerQuantity;
                            let modifiedOffer = offerDiscountForQuantity({...existingOffer}, newQuantity);
                            newOffers = newOffers.map((existingOffer) => {
                                if (existingOffer.stock_id === offer.stock_id) {
                                    return modifiedOffer;
                                }
                                return existingOffer;
                            });
                        } else {
                            newOffers.push(offer);
                        }
                    }
                    return {
                        product: cartItem.product,
                        quantity: cartItem.quantity + quantity,
                        offers: newOffers
                    };
                }
                return cartItem;
            });
            dispatch(setCart(newCart));
            return;
        } else {
            dispatch(setCart([...local.cart, { product, quantity, offers }]));
        }
    };

    const handleShowCookieModal = () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setCookieModalVisible(true);
        setShowCookieModal(true);
        const landing = document.getElementById('landing');
        if (landing) {
          landing.classList.add('overflow-y-hidden');
        }
    };
    
    const handleHideCookieModal = () => {
        setShowCookieModal(false);    
        timeoutRef.current = setTimeout(() => {
            setCookieModalVisible(false);
            const landing = document.getElementById('landing');
            if (landing) {
            landing.classList.remove('overflow-y-hidden');
            }
        }, 700);
    };

    const handleSetCookies = (setting) => {
        if (!isLocalhost && !isBotTraffic && (setting === "all" || setting === "analytics")) {
            initAnalytics();
            initChat();
        }
        handleHideCookieModal();
        dispatch(setCookies(setting));
    }

    const getCanonicalUrl = () => {
        return (url => url.replace(/\/(?=\?|$)/, ''))(window.location.href);
    }

    return (
        <div id="store" className={`flex justify-center vertical relative align-center`}>
            {!is404 && !gettingCategories && !gettingManufacturers && !gettingProducts && <Helmet>
                <link rel="canonical" href={getCanonicalUrl()} />
            </Helmet>}
            {is404 && <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>}
            {(!is404 && !showCart && !currentProduct && pathname === "/") && (<Helmet>
                <meta
                    name="description"
                    content="Materialize is your trusted destination for certified excess genuine parts sourced from advanced manufacturers' extra inventory. Explore a unique selection of hard-to-find parts certified excess stock from verified manufacturers - authenticity guaranteed."
                />
                <meta property="og:title" content={"Materialize Shop - Certified Excess"} />
                <meta property="og:description" content={"Shop for parts sourced directly from certified manufacturers' excess inventory. Select from a wide range of products from various manufacturers, all at a discounted price."} />
                <meta property="og:image" content={LogoWhite} />
                <meta property="og:url" content={"https://shop.trymaterialize.com"} />
                <meta name="twitter:title" content="Materialize Shop - Certified Excess" />
                <meta name="twitter:description" content="Shop parts sourced directly from certified manufacturers' excess inventory." />
                <meta name="twitter:url" content="https://shop.trymaterialize.com" />
                <meta name="twitter:card" content="Shop from a wide range of products from various certified manufacturers' inventory, all at a discounted price."></meta>
                <title>{`Materialize - Shop Certified Excess`}</title>
            </Helmet>)}
            {!is404 && cookieModalVisible && <CookieSettings handleSetCookies={handleSetCookies} showCookieModal={showCookieModal} handleHideCookieModal={handleHideCookieModal} classes={`${showCookieModal ? "fade-in" : "fade-out no-pointer"}`} />}
            <StoreHeader
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
                cart={local.cart}
                handleViewProduct={handleViewProduct}
                hrefForViewProduct={hrefForViewProduct}
                showCart={showCart} />
            <div id="scroll-view" className="flex vertical flex-1 width-100 overflow-y-scroll overflow-x-hidden align-center">
                {(!is404 && !showCart && !currentProduct && pathname === "/") && (
                    <Banner handleViewProduct={handleViewProduct} hrefForViewProduct={hrefForViewProduct} />
                )}
                <div className="shop-max-width flex horizontal flex-1 width-100">
                    <StoreContent
                        showCart={showCart}
                        showAddedToCartToast={showAddedToCartToast}
                        setShowAddedToCartToast={setShowAddedToCartToast}
                        cart={local.cart}
                        handleViewProduct={handleViewProduct}
                        hrefForViewProduct={hrefForViewProduct}
                        handleCategoryChangeNew={handleCategoryChangeNew}
                        hrefForHandleCategoryChangeNew={hrefForHandleCategoryChangeNew}
                        alsoViewedProducts={alsoViewed}
                        pathBreadcrumbs={pathBreadcrumbs}
                        currentProduct={currentProduct}
                        addToCart={addToCart}
                        setShowSuccessToast={setShowSuccessToast}
                        manufacturers={rawManufacturers}
                        categories={categories} />
                </div>
            </div>
            <div id="keystone-user-toasts-wrapper">
                <div className="flex vertical flex-1 v-gap-s pad-l align-center">
                    {showSuccessToast && <Toast message="Order Request Submitted Successfully" type="user" state="positive" />}
                    {showAddedToCartToast && <Toast message="Added to your cart" type="user" state="positive" />}
                </div>
            </div>
            {local?.cookies === "unset" && (
                <div id="cookie-banner">
                    <div className="blue-bg flex horizontal mobile-vertical pad-xl h-gap-xl v-gap-m space-between align-center">
                        <div className="flex vertical v-gap-xs">
                            <Label content="We value your privacy" size="l" semiBold={true} line="l" />
                            <Label content='We use cookies to enhance your browsing experience and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.' size="s" mono={true} line="s" />
                        </div>
                        <div className="flex horizontal h-gap-m mobile-width-100">
                            <Button label="Customize" secondary={true} classes="white-bg no-shadow no-border" large={true} onClick={handleShowCookieModal} mobileFlex={true} />
                            <Button label="Accept All" large={true} classes="yellow-bg" onClick={() => handleSetCookies("all")} mobileFlex={true} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Store;