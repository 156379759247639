import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Store from './page/Store';

function App() {
  window.recaptchaOptions = {
    useRecaptchaNet: true,
    enterprise: true,
  };

  return (
    <Router>
      <Routes>
        <Route key={"/"} path={"/"} element={<Store />} />
        <Route key={"/categories/*"} path={"/categories/*"} element={<Store />} />
        <Route key={"/products/:productManufacturer/:productPartNumber"} path={"/products/:productManufacturer/:productPartNumber"} element={<Store />} />
        <Route key={"/manufacturers/:manufacturer"} path={"/manufacturers/:manufacturer"} element={<Store />} />
        <Route key={"/search"} path={"/search"} element={<Store />} />
        <Route key={"/cart"} path={"/cart"} element={<Store />} />
        <Route key={"*"} path={"*"} element={<Store />} />
      </Routes>
    </Router>
  );
}

export default App;