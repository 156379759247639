import Icon from './Icon';
import Label from './Label';
import './style/ChipRounded.css';
import React from 'react';

const ChipRounded = ({ index, label, uppercase = true, secondary = true, displayFont = false, small, positive = false, warning = false, negative = false, icon = null, flex = false, centeredText = false, brand = false, bg = null, text = null}) => {
    return (
        <div key={index} className={`keystone-chip-rounded ${secondary && !bg ? "secondary" : ""} ${positive ? "positive" : ""} ${negative ? "negative" : ""} ${warning ? "warning" : ""} ${brand ? "brand" : ""} ${small ? "small" : ""} ${icon ? "icon" : ""} ${flex ? "flex-1 width-100" : ""} ${bg ? `${bg}-bg` : ""}`}>
            {icon && <Icon icon={icon} color={positive ? 'rgb(38, 92, 38)' : 'white'} size='18' />}
            <Label header='h4' content={label} size={small ? "xs" : "s"} line={small ? "xs" : "s"} semiBold={true} displayFont={displayFont} mono={!displayFont} uppercase={uppercase} center={centeredText} color={text ? text : null} />
        </div>
    );
};

export default ChipRounded;