import Label from './Label';
import './style/Tile.css';
import React from 'react';

const Tile = ({ index, onClick = null, content, rowStyle = false, href = null, classes = "", style = {} }) => {
    return (
        <div key={index} className={`tile ${rowStyle ? "row-style" : ""} ${onClick ? "clickable" : ""} ${classes}`} style={style} onClick={onClick}>
            {href && <a href={href} className='invisible'>{content}</a>}
            {content || <Label content="Make sure to set the content property on the Tile component" />}
        </div>
    );
};

export default Tile;