import './style/StoreContent.css';
import './style/Cart.css';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import Label from '../block/Label';
import { offerDiscountForQuantity } from '../../util/Calculate';
import { getImgUrl } from '../../util/ImageSource';
import { useDispatch } from 'react-redux';
import Button from '../block/Button';
import { submitOffer } from '../../redux/temporarySlice';
import { setCart } from '../../redux/localSlice';
import { useSelector } from 'react-redux';
import TrashIcon from '../../media/trash@3x.png';
import CartDisplayIcon from '../../media/cart-display@3x.png';
import Icon from '../block/Icon';
import Image from '../block/Image';
import Tile from '../block/Tile';
import LinkButtonWrapper from '../block/LinkButtonWrapper';
import StockRow from '../block/StockRow';
import SummaryFooter from '../block/SummaryFooter';
import PlaneFill from '../../media/plane_fill_24.png';
import ConfirmOfferModal from '../block/ConfirmOfferModal';
import useResponsiveBreakpoint from '../../hooks/ResponseBreakpoint';
import Divider from '../block/Divider';
import useDimensionObserver from '../../hooks/DimensionObserver';

const arrayJoin = (arr, el) => arr.flatMap((e, i) => i < arr.length - 1 ? [e, el] : [e]);


const Cart = (
    { 
        items,
        handleViewProduct,
        hrefForViewProduct,
        setShowSuccessToast
    }
) => {
    
    const dispatch = useDispatch();
    const temporary = useSelector((state) => state.temporary);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [captchaComplete, setCaptchaComplete] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState("");
    const [awaitingSubmittedOffer, setAwaitingSubmittedOffer] = useState(false);
    const [errorSubmittingOffer, setErrorSubmittingOffer] = useState(null);
    const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    
    const [isStockWrapped, setIsStockWrapped] = useState(false);

    const productDetailsContainerRef = useRef(null);
    const productDetailsWidthIsSmall = useResponsiveBreakpoint(productDetailsContainerRef, 975, 'width');
    const productDetailsContainerWidth = useDimensionObserver(productDetailsContainerRef)?.width;

    const productDescriptionRef = useRef(null);
    const productDescriptionWidth = useDimensionObserver(productDescriptionRef)?.width;

    const hideStockRowHeader = useMemo(() => isStockWrapped || productDetailsWidthIsSmall, [isStockWrapped, productDetailsWidthIsSmall]);
    const showTrashAtEnd = useMemo(() => !productDetailsWidthIsSmall, [productDetailsWidthIsSmall]);
    const shouldForceCard = !showTrashAtEnd;
    const shouldShowDescriptionUnderImage = productDetailsWidthIsSmall;

    const shoppingCartItemDividerShouldBeVertical = useMemo(() => productDescriptionWidth <= productDetailsContainerWidth * 0.9, [productDescriptionWidth, productDetailsContainerWidth]);

    const [contactInfo, setContactInfo] = useState({
        fullName: "",
        email: "",
        company: "",
    });

    const validateEmail = (email) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    };

    useEffect(() => {
        let valid = true;

        if (name?.length === 0 || email?.length === 0 || addressLine1?.length === 0 || city?.length === 0 || zip?.length === 0 || state?.length === 0 || country?.length === 0 || !captchaComplete) {
            valid = false;
        }

        if (!validateEmail(email)) {
            valid = false;
        }

        setSubmitEnabled(valid);
    }, [email, name, addressLine1, addressLine2, city, zip, state, country, captchaComplete]);

    useEffect(() => {
        if (temporary?.submittingOffer) {
            setAwaitingSubmittedOffer(true);
        }
    }, [temporary?.submittingOffer]);

    useEffect(() => {
        if (temporary?.errorSubmittingOffer !== null && awaitingSubmittedOffer) {
            setErrorSubmittingOffer(temporary.errorSubmittingOffer);
        } else if (temporary?.submittedOffers?.length > 0 && awaitingSubmittedOffer) {
            setEmail("");
            setName("");
            setAddressLine1("");
            setAddressLine2("");
            setCity("");
            setZip("");
            setState("");
            setCountry("");
            setCaptchaResponse("");
            setErrorSubmittingOffer(null);
            setShowSuccessToast(true);
            dispatch(setCart([]));
        }
        setAwaitingSubmittedOffer(false);
    }, [temporary?.errorSubmittingOffer, temporary?.submittedOffers]);
    // TODO: I need to figure out why fixing this lint breaks the flow...

    const handleOfferRequest = () => {
        let lineItems = [];

        items.forEach((cartItem) => {
            cartItem.offers.forEach((offer) => {
                lineItems.push({
                    shop_listing_id: cartItem.product.id,
                    stock_id: offer.stock_id,
                    mpn: cartItem.product.marketMpn,
                    manufacturer: cartItem.product.marketManufacturer,
                    quantity: offer.offerQuantity,
                    unit_price: offer.offerPrice,
                    currency: "USD"
                });
            });
        });

        let offer = {
            recaptcha_response: captchaResponse,
            line_items: lineItems,
            buyer: {
                email: contactInfo.email,
                full_name: contactInfo.fullName,
                company: contactInfo.company
            }
        }

        dispatch(submitOffer({ offer }));
    };

    const handleDeleteCartItem = (e, cartItem) => {
        e.stopPropagation();
        dispatch(setCart(items.filter((item) => item.product.marketManufacturer !== cartItem.product.marketManufacturer && item.product.marketMpn !== cartItem.product.marketMpn)));
    };

    const cartSummary = useMemo(() => {
        const totalQuantity = items.reduce((acc, cartItem) => acc + cartItem.offers.reduce((acc, offer) => acc + offer.offerQuantity, 0), 0);
        const totalPrice = items.reduce((acc, cartItem) => acc + cartItem.offers.reduce((acc, offer) => acc + (offer.offerPrice * offer.offerQuantity), 0), 0);
        const unitPrice = (totalPrice / totalQuantity) || 0;
        // TODO: Okay so totalQuantity 0 would mean Infinity unit price. That's hilarious. But bad ux. Fix maybe. Definitely.
        const totalShipments = items.reduce((acc, cartItem) => acc + cartItem.offers.reduce((acc, offer) => offer.offerQuantity > 0 ? acc + 1 : acc, 0), 0);
        return {
            totalQuantity,
            totalPrice,
            unitPrice,
            totalShipments
        };
    }, [items]);

    const handleOfferPriceChange = (e, index) => {
        const price = Number(e.target?.value || 0);
        let cartItemWithOffer = items.find(cartItem => cartItem.offers.find(offer => offer.stock_id === index));
        // TODO: Learn why offerToModify.offerPrice = price will throw an error, you uneducated fool
        let offerToModify = cartItemWithOffer.offers.find(offer => offer.stock_id === index);
        let modifiedOffer = {...offerToModify, offerPrice: price.toString()};
        dispatch(setCart(items.map((item) => item.product.marketMpn === cartItemWithOffer.product.marketMpn ? { ...item, offers: item.offers.map(offer => offer.stock_id === index ? modifiedOffer : offer) } : item)));
    };

    const handleOfferQuantityChange = (e, index) => {
        const quantity = Number(e.target?.value) || (e === 'all' ? e : 0);
        let cartItemWithOffer = items.find(cartItem => cartItem.offers.find(offer => offer.stock_id === index));
        let offerToModify = cartItemWithOffer.offers.find(offer => offer.stock_id === index);
        let modifiedOffer = offerDiscountForQuantity({...offerToModify}, quantity);
        dispatch(setCart(items.map((item) => item.product.marketMpn === cartItemWithOffer.product.marketMpn ? { ...item, offers: item.offers.map(offer => offer.stock_id === index ? modifiedOffer : offer) } : item)));
    };

    const handleConfirmOffer = () => {
        // fake button load
        setConfirmButtonLoading(true);
        setTimeout(() => {
            setConfirmButtonLoading(false);
            setShowConfirmModal(true);
        }, 700);
    };

    return (
        <div className="flex vertical mobile-footer-pad v-gap-m">
            <Label content="Your Items" size="m" semiBold={true} displayFont={true} classes="pad-m no-pad-bot no-pad-left" header='h2'/>
            <div className="flex vertical v-gap-xs">
                {items?.length > 0 && !hideStockRowHeader && <div className={`flex horizontal transparent-border`}>
                    <div className="flex horizontal product-details-header">
                        <Label content="Product Details" displayFont={true} semiBold={true} size="s" flex={2} />
                    </div>
                    <div className="flex horizontal pad-m no-pad-top no-pad-bot h-gap-m stock-details-row-header">
                        <div style={{width: "24px", minWidth: "24px"}}></div>
                        <Label content="Quantity" size="s" semiBold={true} displayFont={true} style={{flexBasis: "124px", flexShrink: "1"}} />
                        <Label content="Approved Price" size="s" semiBold={true} displayFont={true} style={{flexBasis: "148px", flexGrow: "1"}} />
                        <Label content="Offer Quantity" size="s" semiBold={true} displayFont={true} style={{flexBasis: "100px"}} />
                        <Label content="Offer Price" size="s" semiBold={true} displayFont={true} style={{flexBasis: "124px"}} />
                    </div>
                    {showTrashAtEnd && <div style={{width: "66px", minWidth: "66px"}}></div>}
                </div>}
                <div ref={productDetailsContainerRef} className="flex vertical v-gap-s">
                    {items?.map((cartItem, index) => (
                        <Tile
                            key={index + cartItem.product.marketMpn}
                            index={index + cartItem.product.marketMpn}
                            rowStyle={true}
                            content={
                                <div className="flex horizontal keystone-card v-gap-m align-center flex-wrap relative cart-item-container" style={{alignItems: "stretch"}}>
                                    <div ref={productDescriptionRef} className={`flex vertical pad-m stock-details-container ${shoppingCartItemDividerShouldBeVertical ? 'border-right': 'border-bottom'}`}>
                                        <div className="flex horizontal pad-m h-gap-m align-start">
                                            <LinkButtonWrapper label='View Product' href={hrefForViewProduct(cartItem.product)}>
                                                <Image source={getImgUrl(cartItem.product)} altTag={cartItem.product.marketMpn} width="medium" noPad={true} onClick={() => handleViewProduct(cartItem.product)} />
                                            </LinkButtonWrapper>
                                            <div className="flex vertical product-details-container">
                                                <Label content={cartItem.product.marketManufacturer || ""} size="s" semiBold={true} mono={true} displayFont={true} secondary={true} />
                                                <Label content={cartItem.product.marketMpn} size="m" mono={true} />
                                                {!shouldShowDescriptionUnderImage && <Label content={cartItem.product.description} size="s" mono={true} secondary={true} classes="cart-product-description multi-line-ellipsis-3-lines" />}
                                            </div>
                                        </div>
                                        {shouldShowDescriptionUnderImage && <div className='flex no-pad-top product-description-under-image'>
                                            <Label content={cartItem.product.description} size="s" mono={true} secondary={true} classes="pad-m no-pad-top cart-product-description multi-line-ellipsis-3-lines" />
                                        </div>}
                                    </div>
                                    <div className="flex vertical justify-center stock-rows-container">
                                        {arrayJoin(cartItem.offers?.map((offer) => (
                                            <StockRow forceCard={shouldForceCard} key={offer?.stock_id} index={offer?.stock_id} stock={offer} handleOfferQuantityChange={handleOfferQuantityChange} handleOfferPriceChange={handleOfferPriceChange} cardStyleBreakpoint={556} wrappedLayoutCallback={setIsStockWrapped} />
                                        )), (<Divider light={true}/>))}
                                    </div>
                                    <div className={`flex pad-m ${isStockWrapped ? 'align-start': 'align-center'} ${!showTrashAtEnd ? 'floater' : 'border-left'}`}>
                                        <Button icon={TrashIcon} iconHoverColor={"#6f7aa5"} onClick={(e) => handleDeleteCartItem(e, cartItem)} />
                                    </div>
                                </div>
                            }
                        />
                    ))}
                    {items?.length === 0 && (
                        <div className="keystone-card flex vertical align-center pad-xl v-gap-m">
                            <Icon icon={CartDisplayIcon} size={44} color="#6f7aa5" />
                            <div className="flex vertical v-gap-xs">
                                <Label content="Your cart is empty" size="m" semiBold={true} displayFont={true} center={true} />
                                <Label content="Items added to your cart will show up here." size="s" semiBold={true} displayFont={true} center={true} secondary={true} />
                            </div>
                            <Button label ="Continue Shopping" onClick={() => window.location.href = "/"} brand={true} />
                        </div>
                    )}
                </div>
                {items?.length > 0 && (
                    <div className="flex vertical v-gap-m pad-l no-pad-left no-pad-right no-pad-bottom">
                        <Label content="Offer Summary" size="m" semiBold={true} displayFont={true} />
                        <div className="flex horizontal keystone-card flex-wrap gap-m align-center pad-m space-between">
                            <SummaryFooter summary={cartSummary} />
                            <Button
                                label="Confirm Offer"
                                brand={true}
                                large={true}
                                icon={PlaneFill}
                                iconSize={14}
                                iconColor="black"
                                loading={confirmButtonLoading}
                                classes="space-between h-gap-m"
                                onClick={handleConfirmOffer}
                                disabled={cartSummary.totalQuantity === 0 || cartSummary?.totalPrice === 0}
                                style={{flexBasis: "80px", flexGrow: "1", padding: "9px 12px", flexDirection:"row-reverse"}} />
                        </div>
                    </div>
                )}
            </div>
            {showConfirmModal && (
                <ConfirmOfferModal
                    showConfirmModal={showConfirmModal}
                    setShowConfirmModal={setShowConfirmModal}
                    offerSummary={cartSummary}
                    handleSubmitOffer={handleOfferRequest}
                    setCaptchaResponse={setCaptchaResponse}
                    setCaptchaComplete={setCaptchaComplete}
                    contactInfo={contactInfo}
                    setContactInfo={setContactInfo}
                />
            )}
        </div>
    );
};

export default Cart;