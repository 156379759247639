import React from 'react';

const LinkButtonWrapper = ({ children, href, label='' }) => {
    return (
        <>
            <a href={href} className='invisible'>{label || href}</a>
            {children}
        </>
    )
};

export default LinkButtonWrapper;