import './style/SiteFooter.css';

import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LinkedIn from '../../media/linkedin_logo_24.png';
import Mail from '../../media/mail_logo_24.png';
import WordmarkBlack from '../../media/materialize-logo-wordmark-black.png';
import WordmarkWhite from '../../media/materialize-logo-wordmark-white.png';
import X from '../../media/x_logo_24.png';
import { } from '../../redux/localSlice';
import { } from '../../redux/temporarySlice';
import Divider from './Divider';
import Icon from './Icon';
import Label from './Label';
import LinkButton from './LinkButton';

const SiteFooter = ({ bgColor = "white", bumpForCookieFooter = true }) => {
    const local = useSelector((state) => state.local);
    const navigate = useNavigate();

    const secondaryText = bgColor === "grey" ? true : false;
    const textColor = bgColor === "white" ? "black" : "secondary";
    const iconColor = bgColor === "grey" ? "on-grey-icon" : "on-black-icon";
    const logo = bgColor === "black" ? WordmarkWhite : WordmarkBlack;

    return (
        <div className="flex pad-m no-pad-bot">
            <div className={`white-bg flex vertical flex-1 v-gap-xl pad-xl ${(local?.cookies === "unset" && bumpForCookieFooter) ? "cookie-banner-bump" : ""}`} style={{borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}>
                <div className="flex horizontal mobile-vertical v-gap-m space-between h-gap-xl">
                    <div className="flex vertical flex-1">
                        <a href="/" className="flex"><img src={logo} alt="Materialize Wordmark" className="wordmark-image" onClick={() => navigate("/")} /></a>
                    </div>
                    <div className="flex horizontal flex-1 justify-end mobile-justify-start h-gap-display">
                        <div className="flex vertical">
                            <div className="flex vertical mobile-align-start justify-center" style={{height: "36px", paddingLeft: "6px", paddingRight: "6px"}}>
                                <Label
                                    mono={true}
                                    content="Platform"
                                    uppercase={true}
                                    secondary={secondaryText}
                                    semiBold={true}
                                    size="s"
                                    color={textColor}
                                />
                            </div>
                            <div className="flex vertical align-start mobile-align-start v-gap-xs">
                                <LinkButton
                                    className={`round-s poppins semi-bold size-l line-l ${textColor}`}
                                    link="https://www.trymaterialize.com"
                                    children="How It Works" />
                                <LinkButton
                                    className={`round-s poppins semi-bold size-l line-l ${textColor}`}
                                    link="https://www.trymaterialize.com/sign-up"
                                    children="Manufacturer Signup" />
                                <LinkButton
                                    className={`round-s poppins semi-bold size-l line-l ${textColor}`}
                                    link="https://app.trymaterialize.com"
                                    children="Client Login" />
                            </div>
                        </div>
                        <div className="flex vertical">
                            <div className="flex vertical mobile-align-start justify-center" style={{height: "36px", paddingLeft: "6px", paddingRight: "6px"}}>
                                <Label
                                    mono={true}
                                    content="Company"
                                    uppercase={true}
                                    secondary={secondaryText}
                                    semiBold={true}
                                    size="s"
                                    color={textColor}
                                />
                            </div>
                            <div className="flex vertical align-start mobile-align-start v-gap-xs">
                                <LinkButton
                                    className={`round-s poppins semi-bold size-l line-l ${textColor}`}
                                    link="https://www.trymaterialize.com/contact-us"
                                    children="Contact Us" />
                                <LinkButton
                                    className={`round-s poppins semi-bold size-l line-l ${textColor}`}
                                    link="https://www.trymaterialize.com/terms-of-use"
                                    children="Terms of Use" />
                                <LinkButton
                                    className={`round-s poppins semi-bold size-l line-l ${textColor}`}
                                    link="https://www.trymaterialize.com/privacy-policy"
                                    children="Privacy Policy" />
                            </div>
                        </div>
                    </div>
                </div>
                <Divider classes={`${bgColor === "black" ? "on-black-divider" : ""}`} />
                <div className="flex horizontal align-center space-between">
                    <div className="flex vertical">
                        <Label
                            mono={true}
                            content="Materialize (Harper AI, Inc.)"
                            uppercase={true}
                            secondary={secondaryText}
                            color={textColor}
                            size="xs"
                            line="xs"
                        />
                        <Label
                            mono={true}
                            content="651 N Broad St, Suite 201 - Middletown, DE 19709"
                            uppercase={true}
                            secondary={secondaryText}
                            color={textColor}
                            size="xs"
                            line="xs"
                        />
                    </div>
                    <div className="flex horizontal h-gap-m">
                        <LinkButton
                            className={`flex icon round-s poppins semi-bold size-l line-l ${textColor}`}
                            link="https://x.com/trymaterialize"
                            children={
                                <Icon
                                    icon={X}
                                    size="28"
                                    color="var(--ks-blue-dark)"
                                    classes={iconColor} />
                            }
                        />
                        <LinkButton
                            className={`flex icon round-s poppins semi-bold size-l line-l ${textColor}`}
                            link="https://www.linkedin.com/company/trymaterialize"
                            children={
                                <Icon
                                    icon={LinkedIn}
                                    size="28"
                                    color="var(--ks-blue-dark)"
                                    classes={iconColor} />
                            }
                        />
                        <LinkButton
                            className={`flex icon round-s poppins semi-bold size-l line-l ${textColor}`}
                            link="mailto:info@trymaterialize.com"
                            children={
                                <Icon
                                    icon={Mail}
                                    size="28"
                                    color="var(--ks-blue-dark)"
                                    classes={iconColor} />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SiteFooter;