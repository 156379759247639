import { useState, useEffect } from 'react';

function useResponsiveBreakpoint(ref, breakpoint, dimension = 'width') {
    const [isSmallerThanBreakpoint, setIsSmallerThanBreakpoint] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (ref.current) {
                let measurement;
                if (dimension === 'width') {
                    measurement = ref.current.getBoundingClientRect()?.width;
                } else {
                    measurement = ref.current.getBoundingClientRect()?.height;
                }
                setIsSmallerThanBreakpoint(measurement < breakpoint);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        // Initial check
        handleResize();

        // Cleanup
        return () => {
            resizeObserver.disconnect();
        };
    }, [ref, breakpoint, dimension]);

    return isSmallerThanBreakpoint;
}

export default useResponsiveBreakpoint;