import { formatToCurrency } from '../../util/Calculate';
import Label from './Label';
import './style/SummaryFooter.css';
import React from 'react';

// TODO: Minor improvement to make summary fields explicitly defined
const SummaryFooter = ({ summary }) => {
    return (
        <div className="flex horizontal h-gap-m v-gap-xl flex-wrap footer-container space-around">
            <div className="flex horizontal h-gap-m space-around grouped-info">
                <div className="flex vertical v-gap-xs align-center" style={{flexShrink: "1"}}>
                    <Label content={summary?.totalShipments?.toString()} size="xl" displayFont={true} semiBold={true} />
                    <Label content="Shipments" size="s" displayFont={true} semiBold={true} secondary={true} doNotBreak={true} />
                </div>
                <div className="flex vertical v-gap-xs align-center" style={{flexShrink: "1"}}>
                    <div className="flex horizontal h-gap-xs align-end">
                        <Label content={summary?.totalQuantity?.toString()} size="xl" displayFont={true} semiBold={true} />
                    </div>
                    <Label content="Offer Quantity" size="s" displayFont={true} semiBold={true} secondary={true} doNotBreak={true} />
                </div>
            </div>
            <div className="flex horizontal h-gap-m space-around grouped-info">
                <div className="flex flex-1 vertical v-gap-xs align-center" style={{flexShrink: "1"}}>
                    <div className="flex horizontal h-gap-xs align-end">
                        <Label content={formatToCurrency(summary?.unitPrice)} size="xl" displayFont={true} semiBold={true} />
                    </div>
                    <Label content="Avg. Offer Price" size="s" displayFont={true} semiBold={true} secondary={true} doNotBreak={true} />
                </div>
                <div className="flex flex-1 vertical v-gap-xs align-center" style={{flexShrink: "1"}}>
                    <Label content={formatToCurrency(summary?.totalPrice)} size="xl" displayFont={true} semiBold={true} />
                    <Label content="Offer Total" size="s" displayFont={true} semiBold={true} secondary={true} doNotBreak={true}  />
                </div>
            </div>
        </div>
    );
};

export default SummaryFooter;