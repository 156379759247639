import './style/Product.css';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import Label from '../component/block/Label';
import ChipRounded from '../component/block/ChipRounded';
import Button from '../component/block/Button';
import Divider from '../component/block/Divider';
import CollapsableSection from '../component/block/CollapsableSection';
import { displayPriceForProduct, formatPercentWithCommas, formatToCurrency, offerDiscountForQuantity, priceForStock, availableQuantityForProduct, quantityTypeForProduct, capitalizeFirstLetter, displayLotSizeForProduct } from '../util/Calculate';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getImgUrl } from '../util/ImageSource';
import Image from '../component/block/Image';
import Tile from '../component/block/Tile';
import Modal from '../component/block/Modal';
import Expand from '../media/expand@3x.png';
import Close from '../media/close@3x.png';
import InfoBubble from '../media/info-bubble-32@3x.png';
import Icon from '../component/block/Icon';
import { useNavigate } from 'react-router-dom';
import CartAddFill from '../media/cart_add_fill_24.png';
import PlaneFill from '../media/plane_fill_24.png';
import StockRow from '../component/block/StockRow';
import { submitOffer } from '../redux/temporarySlice';
import SummaryFooter from '../component/block/SummaryFooter';
import ConfirmOfferModal from '../component/block/ConfirmOfferModal';
import StockRowV2 from '../component/block/StockRow';
import useResponsiveBreakpoint from '../hooks/ResponseBreakpoint';

const Product = ({ currentProduct, addToCart, cart, handleViewProduct, hrefForViewProduct, alsoViewedProducts, setShowAddedToCartToast }) => {
    const showOfferConfirmation = useSelector((state) => state.temporary?.showOfferConfirmation);
    const [isStockWrapped, setIsStockWrapped] = useState(false);

    const isHeaderHidden = useMemo(() => isStockWrapped, [isStockWrapped]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showImageModal, setShowImageModal] = useState(false);
    const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);

    const [buttonLoading, setButtonLoading] = useState(false);
    const [fullDescription, setFullDescription] = useState(false);
    const [addedQuantity, setAddedQuantity] = useState(0);

    const [captchaResponse, setCaptchaResponse] = useState("");
    const [captchaComplete, setCaptchaComplete] = useState(false);

    const productContainerRef = useRef(null);
    const productContainerBelowBreakpoint = useResponsiveBreakpoint(productContainerRef, 767, 'width')

    const shouldContentBeSingleColumn = useMemo(() => productContainerBelowBreakpoint, [productContainerBelowBreakpoint]);
    
    const questions = [
        {
            question: "Are your stock levels accurate?",
            answer: "Absolutely! We regularly verify stock with our storage manufacturers or ERAI distributors."
        },
        {
            question: "How do you ensure manufacturers are legitimate?",
            answer: "We conduct comprehensive KYC checks, including verifying business registrations and credentials, ensuring ISO9001 compliance."
        },
        {
            question: "Why is your lead time so short?",
            answer: "Our stock consists of certified excess stored in our manufacturers' warehouses, allowing us to ship confirmed orders within 5-7 business days."
        },
        {
            question: "What is your return policy?",
            answer: "Given our rigorous quality controls and the risk of fraudulent component swaps, we only accept returns for verifiable quality issues."
        }
    ];

    const [stockOffers, setStockOffers] = useState([]);

    const [contactInfo, setContactInfo] = useState({
        fullName: "",
        email: "",
        company: "",
    });

    useEffect(() => {
        if (currentProduct || !showOfferConfirmation) {
            let currentStock = [...currentProduct.stock];

            setStockOffers(currentStock.map(stock => {
                const basePrice = priceForStock(currentProduct, stock);
                return {
                    ...stock,
                    originalPrice: basePrice,
                    approvedPrice: basePrice,
                    offerPrice: basePrice,
                    offerQuantity: 0, // TODO: WHAT THE FUCK? CAMELCASE? ITS AN API CALL! HE IS MIXING SNAKE AND CAMELS! SNAKES EAT CAMELS!
                    discount: 0,
                    boxMinimum: stock?.warehouse_minimum
                };
            }));
        }
    }, [currentProduct, showOfferConfirmation]);

    const offerSummary = useMemo(() => {
        const totalQuantity = stockOffers.reduce((acc, stock) => acc + stock.offerQuantity, 0);
        const totalPrice = stockOffers.reduce((acc, stock) => acc + (stock.offerPrice * stock.offerQuantity), 0);
        const unitPrice = totalPrice / totalQuantity || 0;
        const totalShipments = stockOffers.reduce((acc, stock) => stock.offerQuantity > 0 ? acc + 1 : acc, 0);
        return {
            totalQuantity,
            totalPrice,
            unitPrice,
            totalShipments
        };
    }, [stockOffers]);

    const handleOfferPriceChange = (e, index) => {
        const price = Number(e.target.value) || 0;
        let newStockOffers = [...stockOffers];
        let modifiedStockOffer = newStockOffers.find(stock => stock.stock_id === index);
        modifiedStockOffer.offerPrice = price;
        setStockOffers(newStockOffers);
    };

    const handleOfferQuantityChange = (e, index) => {
        const quantity = Number(e.target?.value) || (e === 'all' ? e : 0);
        let newStockOffers = [...stockOffers];
        offerDiscountForQuantity(newStockOffers.find(stock => stock.stock_id === index), quantity);
        setStockOffers(newStockOffers);
    };

    const handleSubmitOffer = () => {
        let lineItems = [];

        stockOffers.forEach((offer) => {
            lineItems.push({
                shop_listing_id: currentProduct.id,
                stock_id: offer.stock_id,
                mpn: currentProduct.marketMpn,
                manufacturer: currentProduct.marketManufacturer,
                quantity: offer.offerQuantity,
                unit_price: offer.offerPrice,
                currency: "USD"
            });
        });

        let offer = {
            recaptcha_response: captchaResponse,
            line_items: lineItems,
            buyer: {
                email: contactInfo.email,
                full_name: contactInfo.fullName,
                company: contactInfo.company
            }
        }

        dispatch(submitOffer({ offer }));
    };

    const handleAddToCart = () => {
        // make button load for a fake 0.14 seconds
        setButtonLoading(true);
        setTimeout(() => {
            setButtonLoading(false);
            setShowAddedToCartToast(true);
            setShowAddedToCartModal(true);
        }, 700);
        addToCart(currentProduct, offerSummary?.totalQuantity, stockOffers.filter(stock => stock.offerQuantity > 0));
    };

    const handleConfirmOffer = () => {
        // fake button load
        setConfirmButtonLoading(true);
        setTimeout(() => {
            setConfirmButtonLoading(false);
            setShowConfirmModal(true);
        }, 700);
    };

    const sku = currentProduct?.marketMpn;
    const brand = currentProduct?.marketManufacturer;
    const title = `Materialize | ${brand} - ${sku}`;
    const description = `In-Stock & Ready to Ship: ${currentProduct?.quantity} units of ${brand} - ${sku}. ${currentProduct?.description || 'All stock sourced from our verified network of manufacturers.'}`;

    const availability = currentProduct?.quantity > 0 ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock';
    const condition = 'http://schema.org/NewCondition';
    const currency = 'USD'; // Adjust this if needed
    const url = `https://shop.trymaterialize.com/${encodeURIComponent(currentProduct.marketManufacturer.toLowerCase())}/${encodeURIComponent(currentProduct.marketMpn.toLowerCase())}`;

    const displayPriceRaw = displayPriceForProduct(currentProduct);
    const displayPrice = displayPriceRaw < 0.01 ? displayPriceRaw.toFixed(5) : displayPriceRaw.toFixed(2);

    // Build structured data
    const structuredData = {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        name: title,
        description: description,
        sku: sku,
        mpn: sku,
        image: [
            getImgUrl(currentProduct),
        ],
        brand: {
            '@type': 'Brand',
            name: brand,
        },
        category: currentProduct?.categoryPath,
        offers: {
            '@type': 'Offer',
            url: url,
            priceCurrency: currency,
            price: displayPrice,
            itemCondition: condition,
            availability: availability,
            mpn: sku,
            inventoryLevel: {
                '@type': 'QuantitativeValue',
                value: availableQuantityForProduct(currentProduct),
                unitText: quantityTypeForProduct(currentProduct),
            },
            category: currentProduct?.categoryPath,
        },
        additionalProperty: currentProduct?.specifications?.map(spec => ({
            '@type': 'PropertyValue',
            name: spec.name,
            value: spec.value,
        })),
        '@graph': [
          {
            "@type": "FAQPage",
            "@id": window.location.href + "#faq",
            "url": window.location.href + "#faq",
            "mainEntity": questions.map(q => (
              {
                "@type": "Question",
                "name": q.question,
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": q.answer
                }
              }))
          }
        ]
    };

    const specifications = () => {
        return (
            <div className="flex vertical v-gap-l pad-l">
                {currentProduct?.specifications?.length > 0 && <Label content="Specifications" semiBold={true} size="m" displayFont={true} />}
                <div className="flex vertical v-gap-xl">
                    {currentProduct?.specificationsByType && Object.keys(currentProduct?.specificationsByType).map((type, index) => (
                        <div className="flex vertical v-gap-s" key={index} label={type}>
                            <Label content={type} semiBold={true} size="s" displayFont={true} />
                            <Divider />
                            {currentProduct?.specificationsByType[type].map((spec, index) => (
                                <div key={index} className="flex horizontal flex-1 width-100 h-gap-xl space-between align-start">
                                        <Label header='h3' content={spec.name} size="s" semiBold={true} displayFont={true} secondary={true} />
                                        <Label
                                            header='h4'
                                            content={spec.name === "Schedule B" ? Array.from(new Set(spec.value.split(/[,|]/).map(s => s.trim()))).join(', ') : ((spec.units && spec.units !== 'null') ? `${spec.value} ${spec.units}` : spec.value)}
                                            size={"s"}
                                            mono={true}
                                            flex={1}
                                            right={true} />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    const getAlsoViewedProducts = () => {
        return alsoViewedProducts.map((product, index) => (
            <Tile
                key={index + product.marketMpn}
                index={index + product.marketMpn}
                onClick={() => handleViewProduct(product)}
                href={hrefForViewProduct(product)}
                content={
                    <div className="flex vertical pad-m height-100 space-between">
                        <Label content={`${product.marketManufacturer} ${product.marketMpn} - Materialize Genuine Excess`} header='h2' classes='invisible' />
                        <div className="flex vertical">
                            <Label header='h3' content={product.marketManufacturer || ""} size="s" semiBold={true} mono={true} displayFont={true} secondary={true} />
                            <Label header='h4' content={product.marketMpn} size="m" mono={true} />
                        </div>
                        <Image source={getImgUrl(product)} altTag={product.marketMpn} fullWidth={true} />
                        <ChipRounded small={true} label={`${product.category}`} />
                        <div className="flex vertical">
                            <Label header='h3' content={`${availableQuantityForProduct(product)} ${capitalizeFirstLetter(quantityTypeForProduct(product))} ${displayLotSizeForProduct(product)}`} size="s" semiBold={true} mono={true} displayFont={true} secondary={true} />
                            <Label header='h4' content={displayPriceForProduct(product) === 0 ? "N/A" : formatToCurrency(displayPriceForProduct(product))} size="m" mono={true} />
                        </div>
                    </div>
                } />
        ));
    };

    const faqWrapper = () => {
        return (
            <div id="faq-wrapper" className={`keystone-card-secondary flex vertical v-gap-m pad-l round-m`}>
                <div className="flex horizontal">
                    <div className="flex vertical flex-1 h-gap-s align-center space-between v-gap-s">
                        <div className="flex align-center yellow-bg pad-s border-100">
                            <Icon icon={InfoBubble} size={18} color="black" />
                        </div>
                        <Label header='h2' content="Frequently Asked Questions" size="m" semiBold={true} displayFont={true}/>
                    </div>
                </div>
                <div className="flex vertical v-gap-xs">
                    {questions.map((question, index) => 
                        <CollapsableSection
                            label={question.question}
                            key={"q" + index}
                            index={"q" + index}
                            content={
                                <div className="flex horizontal pad-s">
                                    <div className="grey-bg flex round-s vertical pad-s v-gap-xs">
                                        <Label
                                            header='h3'
                                            content={question.answer}
                                            mono={true}
                                            size="xs"
                                            line="xs"
                                            semiBold={true}
                                            color="black"
                                        />
                                    </div>
                                </div>
                            }
                        />
                    )}
                </div>
            </div>
        )
    }

    const handleViewCart = () => {
        setShowAddedToCartModal(false);
        navigate('/cart');
    };

    return (
        <>
            {currentProduct && <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="sku" content={sku} />
                <meta name="mpn" content={sku} />
                <meta name="brand" content={brand} />
                <meta name="price" content={displayPrice} />
                <meta name="availability" content={availableQuantityForProduct(currentProduct) > 0 ? 'In Stock' : 'Out of Stock'} />
                <meta name="title" content={title} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={getImgUrl(currentProduct)} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="product" />
                <meta property="product:price:amount" content={displayPrice} />
                <meta property="product:price:currency" content="USD" />
                <meta property="product:availability" content={availableQuantityForProduct(currentProduct) > 0 ? 'in stock' : 'out of stock'} />

                {/* Structured Data */}
                <script type="application/ld+json">
                {JSON.stringify(structuredData)}
                </script>
            </Helmet>}
            <div id="product-page" ref={productContainerRef} className={`flex ${shouldContentBeSingleColumn ? 'vertical' : 'horizontal'} flex-1 v-gap-m width-100 h-gap-s`}>
                <div className="flex vertical flex-2 mobile-flex-reset v-gap-xl">
                    <div className="keystone-card flex vertical v-gap-s overflow-hidden">
                        <div className="flex horizontal align-center flex-1">
                            <div className="flex vertical flex-1 align-center justify-center v-gap-xs">
                                <Image
                                    customId="product-page-image"
                                    source={getImgUrl(currentProduct)}
                                    altTag={`${currentProduct?.marketManufacturer} - ${currentProduct?.marketMpn}`}
                                    fullWidth={true}
                                    overlayContent={(
                                        <Button icon={Expand} iconColor="#6f7aa5" iconSize={20} onClick={() => setShowImageModal(true)} />
                                    )}
                                    />
                            </div>
                            <div className="flex vertical flex-2 v-gap-s pad-xl">
                                <div className="flex vertical v-gap-xs">
                                    <div className="flex vertical v-gap-xxs">
                                        <Label header='h2' content={currentProduct?.marketManufacturer} semiBold={true} mono={true} size="s" secondary={true} uppercase={true} />
                                        <Label header='h3' content={currentProduct?.marketMpn} mono={true} size="display" uppercase={true} />
                                    </div>
                                    <div className="flex horizontal h-gap-s">
                                        {currentProduct?.specifications?.some(spec => spec.name === "Case/Package") && (
                                            <ChipRounded label={currentProduct?.specifications?.find(spec => spec.name === "Case/Package")?.value} secondary={true} small={true} />
                                        )}
                                        {currentProduct?.aspects?.rohs_compliant && (
                                            <ChipRounded label="RoHS" secondary={true} small={true} />
                                        )}
                                        {currentProduct?.aspects?.lead_free && (
                                            <ChipRounded label="Lead Free" secondary={true} small={true} />
                                        )}
                                    </div>
                                </div>
                                <div className="flex vertical v-gap-s">
                                    <Label
                                        content={fullDescription ? currentProduct?.description : currentProduct?.description?.length > 200 ? currentProduct?.description?.slice(0, 200) + "..." : currentProduct?.description}
                                        size="s"
                                        mono={true}
                                        header="h3"
                                        line="s" />
                                    {currentProduct?.description?.length > 200 && <Button label={fullDescription ? "Hide Full Description" : "Show Full Description"} onClick={() => setFullDescription(!fullDescription)} secondary={true} onWhite={true} />}
                                </div>
                                {(currentProduct?.stock?.some(s => s?.warehouse_certifications?.length)) && <div className="flex vertical pad-m no-pad-right no-pad-left v-gap-xs">
                                    <Label header='h2' content="Certifications Held by Storing Facility" size="s" semiBold={true} mono={true} uppercase={true} secondary={true} />
                                    <div className="flex vertical">
                                        { currentProduct?.stock?.map(stock => stock?.warehouse_certifications?.map((cert) => (
                                            <Label header='h3' content={
                                                cert?.startsWith('ISO 9001') ? 
                                                    `${cert} - Quality Management Standard` :
                                                    cert?.startsWith('AS9100') ?
                                                        `${cert} - Aerospace Management Standard` :
                                                        cert
                                            }
                                            size="s" mono={true} line="s" uppercase={true} />
                                        )))}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="flex vertical">
                        <div
                            className="flex vertical yellow-bg pad-m"
                            style={{borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}>
                            <div className="flex horizontal flex-end">
                                <Label
                                    content="Save up to 20% by ordering more!"
                                    size="s"
                                    semiBold={true}
                                    color="white"
                                    displayFont={true}
                                    style={{
                                        backgroundColor: 'var(--ks-dark-blue',
                                        borderRadius: "30px",
                                        padding: "8px 12px"
                                    }} />
                            </div>
                            <div className="flex vertical pad-m no-pad-top no-pad-bot">
                                <Label
                                    content="Availability"
                                    size="xl"
                                    semiBold={true}
                                    displayFont={true}/>
                                <Label
                                    content="All stock is new, sourced from certified manufacturers."
                                    size="m"
                                    line="m"
                                    displayFont={true}
                                    style={{fontWeight: "400"}} />
                            </div>
                        </div>
                        {!isHeaderHidden && <div className="flex horizontal h-gap-m black-bg pad-xs" style={{paddingLeft: "70px", paddingRight: "34px"}}>
                            <Label content="Quantity" size="s" semiBold={true} displayFont={true} color="white" style={{flexBasis: "124px", flexShrink: "1"}} />
                            <Label content="Approved Price" size="s" semiBold={true} displayFont={true} color="white" style={{flexBasis: "148px", flexGrow: "1"}} />
                            <Label content="Offer Quantity" size="s" semiBold={true} displayFont={true} color="white" style={{flexBasis: "100px"}} />
                            <Label content="Offer Price" size="s" semiBold={true} displayFont={true} color="white" style={{flexBasis: "124px"}} />
                        </div>}
                        <div
                            className="flex vertical keystone-card-secondary pad-m"
                            style={{
                                borderRadius: "0",
                                borderTop: "none"
                            }}>
                            <div className="flex vertical keystone-card">
                            {stockOffers?.map((stock) => (
                                <StockRow key={stock?.stock_id} index={stock?.stock_id} stock={stock} handleOfferQuantityChange={handleOfferQuantityChange} handleOfferPriceChange={handleOfferPriceChange} wrappedLayoutCallback={setIsStockWrapped} />
                            ))}
                            </div>
                        </div>
                        <div
                            className="flex horizontal flex-wrap keystone-card gap-m align-center pad-m space-between"
                            style={{
                                borderTop: "none",
                                borderTopLeftRadius: "0",
                                borderTopRightRadius: "0"
                            }}>
                            <SummaryFooter summary={offerSummary} />
                            <Button
                                label="Add to Cart"
                                secondary={true}
                                large={true}
                                icon={CartAddFill}
                                iconSize={18}
                                iconColor="black"
                                classes="space-between h-gap-m"
                                onClick={handleAddToCart}
                                disabled={offerSummary.totalQuantity === 0 || offerSummary?.totalPrice === 0}
                                style={{flexBasis: "80px", flexGrow: "1", padding: "8px 12px", flexDirection:"row-reverse"}} />
                            <Button
                                label="Confirm Offer"
                                brand={true}
                                large={true}
                                icon={PlaneFill}
                                iconSize={14}
                                iconColor="black"
                                loading={confirmButtonLoading}
                                classes="space-between h-gap-m"
                                onClick={handleConfirmOffer}
                                disabled={offerSummary.totalQuantity === 0 || offerSummary?.totalPrice === 0}
                                style={{flexBasis: "80px", flexGrow: "1", padding: "9px 12px", flexDirection:"row-reverse"}} />
                        </div>
                    </div>
                    {!shouldContentBeSingleColumn && faqWrapper()}
                    {!shouldContentBeSingleColumn && <div className="flex vertical v-gap-s -hide">
                        <div className="flex vertical">
                            <Label content="People also viewed" size="m" semiBold={true} displayFont={true}/>
                        </div>
                        <div className="grid">
                            {getAlsoViewedProducts()}
                        </div>
                    </div>}
                </div>
                {currentProduct?.specifications?.length > 0 && (<div className="flex vertical flex-1 v-gap-xl">
                    <div className={`flex ${shouldContentBeSingleColumn ? 'vertical': 'horizontal'} h-gap-m`}>
                        <div id="order-add" className="flex vertical flex-1 keystone-card">
                            {currentProduct?.specifications?.length > 0 && (
                                specifications()
                            )}
                        </div>
                    </div>
                    {/* {currentProduct?.specifications?.length > 0 && (
                        <div className="flex vertical flex-1 mobile-flex-reset keystone-card-secondary mobile-vertical reverse desktop-hide">
                            {specifications()}
                        </div>
                    )} */}
                    {shouldContentBeSingleColumn && faqWrapper()}
                    {shouldContentBeSingleColumn && <div className="flex vertical v-gap-s">
                        <div className="flex vertical">
                            <Label header='h4' content="People also viewed" size="m" semiBold={true} displayFont={true}/>
                        </div>
                        <div className="grid flex-1 h-gap-s v-gap-m">
                            {getAlsoViewedProducts()}
                        </div>
                    </div>}
                </div>)}
            </div>
            {showAddedToCartModal && <Modal showModal={showAddedToCartModal} setShowModal={setShowAddedToCartModal} content={(
                <div id="modal-shadow" className="small-modal flex vertical pad-xl v-gap-m" onClick={null}>
                    <div className="flex horizontal flex-1 space-between align-center h-gap-xl">
                        <Label content="Added to Cart" displayFont={true} size="l" semiBold={true} />
                        <Button icon={Close} iconColor="#6f7aa5" iconSize={20} onClick={() => setShowAddedToCartModal(false)} />
                    </div>
                    <div className="flex vertical v-gap-xl flex-1 h-gap-xl">
                        <div className="flex horizontal flex-1 h-gap-xl">
                            <img src={getImgUrl(currentProduct)} alt={`${currentProduct?.marketManufacturer} - ${currentProduct?.marketMpn}`} style={{width: "100px", height: "auto", objectFit: "contain", margin: "0 auto"}} />
                            <div className="flex vertical flex-1 justify-center">
                                <Label content={currentProduct?.marketManufacturer} semiBold={true} mono={true} size="s" secondary={true} uppercase={true} />
                                <Label content={currentProduct?.marketMpn} mono={true} size="l" uppercase={true} />
                                <Label content={currentProduct?.description} size="s" mono={true} secondary={true} classes="multi-line-ellipsis-3-lines" />
                            </div>
                        </div>
                        <div className="flex vertical v-gap-s">
                            <Label content="Offer Summary" displayFont={true} size="l" semiBold={true} />
                            <div className="grey-bg pad-s round-s">
                                <SummaryFooter summary={offerSummary} />
                            </div>
                        </div>
                        <div className="flex vertical flex-1 v-gap-s justify-center">
                            <Button label="Continue Shopping" onClick={() => setShowAddedToCartModal(false)} brand={true} flexHorizontal={true} large={true} />
                            <Button label="View Cart" onClick={handleViewCart} secondary={true} flexHorizontal={true} large={true}  />
                        </div>
                    </div>
                </div>
            )}/>}
            {showConfirmModal && (
                <ConfirmOfferModal
                    showConfirmModal={showConfirmModal}
                    setShowConfirmModal={setShowConfirmModal}
                    currentProduct={currentProduct}
                    offerSummary={offerSummary}
                    handleSubmitOffer={handleSubmitOffer}
                    setCaptchaResponse={setCaptchaResponse}
                    setCaptchaComplete={setCaptchaComplete}
                    contactInfo={contactInfo}
                    setContactInfo={setContactInfo}
                />
            )}
            {showImageModal && <Modal showModal={showImageModal} setShowModal={setShowImageModal} content={(
                <Image
                    customId="modal-shadow"
                    source={getImgUrl(currentProduct)}
                    altTag={`${currentProduct?.marketManufacturer} - ${currentProduct?.marketMpn}`}
                    fullWidth={true}
                    overlayContent={(
                        <Button icon={Close} iconColor="#6f7aa5" iconSize={20} onClick={() => setShowImageModal(false)} />
                    )} />
            )}/>}
        </>
    );
};

export default Product;