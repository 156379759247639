import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const getApiBaseUrl = () => {
    return window.location.origin.includes('local') ? 'http://localhost:8080' : 'https://api.trymaterialize.com';
};

export const submitOffer = createAsyncThunk(
    'store/submitOffer',
    async ({ offer }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/store/submit_offer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(offer)
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const getProducts = createAsyncThunk(
    'store/search',
    async ({ query, category, manufacturer, page, limit = 25 }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/store/search?q=${query}&manufacturer=${manufacturer}&categoryPath=${category}&limit=${limit}&page=${page}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const getAlsoViewedProducts = createAsyncThunk(
    'store/search_also_viewed',
    async ({ query, category, manufacturer, page, limit = 25 }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/store/search?q=${query}&manufacturer=${manufacturer}&categoryPath=${category}&limit=${limit}&page=${page}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const getFeaturedProducts = createAsyncThunk(
    'store/featured',
    async (thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/store/search?categoryPath=test-equipment&limit=50&page=0`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const getCategories = createAsyncThunk(
    'store/categories',
    async (thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/store/categories`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const getManufacturers = createAsyncThunk(
    'store/manufacturers',
    async (thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/store/manufacturers`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const temporarySlice = createSlice({
    name: 'temporary',
    initialState: {
        submittedOffers: [],
        showOfferConfirmation: false,
        errorSubmittingOffer: null,
        products: [],
        searchResults: [],
        featuredProducts: [],
        alsoViewedProducts: [],
        categories: [],
        manufacturers: [],
        page: 0,
        totalPages: 0,
        totalResults: 0
    },
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setShowOfferConfirmation: (state, action) => {
            state.showOfferConfirmation = action.payload;
        },
        emptySearchResults: (state, action) => {
            state.searchResults = [];
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(submitOffer.pending, (state) => {
            state.submittingOffer = true;
            state.errorSubmittingOffer = null;
        })
        .addCase(submitOffer.fulfilled, (state, action) => {
            state.submittingOffer = false;
            state.showOfferConfirmation = true;
            state.submittedOffers.push(action.payload);
        })
        .addCase(submitOffer.rejected, (state, action) => {
            state.submittingOffer = false;
            state.errorSubmittingOffer = action.payload;
        });
        builder
        .addCase(getProducts.pending, (state) => {
            state.gettingProducts = true;
            state.errorGettingProducts = null;
        })
        .addCase(getProducts.fulfilled, (state, action) => {
            if (!action?.meta?.arg?.limit) {
                state.products = action.payload?.results;
                state.page = action.payload?.page;
                state.totalPages = action.payload?.total_pages;
                state.totalResults = action.payload?.total_results;
            } else {
                state.searchResults = action.payload?.results;
            }

            state.gettingProducts = false;
        })
        .addCase(getProducts.rejected, (state, action) => {
            state.gettingProducts = false;
            state.errorGettingProducts = action.payload;
        });
        builder
        .addCase(getFeaturedProducts.pending, (state) => {
            state.gettingFeaturedProducts = true;
            state.errorGettingFeaturedProducts = null;
        })
        .addCase(getFeaturedProducts.fulfilled, (state, action) => {
            state.gettingFeaturedProducts = false;
            state.featuredProducts = action.payload?.results;
        })
        .addCase(getFeaturedProducts.rejected, (state, action) => {
            state.gettingFeaturedProducts = false;
            state.errorGettingFeaturedProducts = action.payload;
        });
        builder
        .addCase(getCategories.pending, (state) => {
            state.gettingCategories = true;
            state.errorGettingCategories = null;
        })
        .addCase(getCategories.fulfilled, (state, action) => {
            state.gettingCategories = false;
            state.categories = action.payload?.categories;
        })
        .addCase(getCategories.rejected, (state, action) => {
            state.gettingCategories = false;
            state.errorGettingCategories = action.payload;
        });
        builder
        .addCase(getManufacturers.pending, (state) => {
            state.gettingManufacturers = true;
            state.errorGettingManufacturers = null;
        })
        .addCase(getManufacturers.fulfilled, (state, action) => {
            state.gettingManufacturers = false;
            state.manufacturers = action.payload?.manufacturers;
        })
        .addCase(getManufacturers.rejected, (state, action) => {
            state.gettingManufacturers = false;
            state.errorGettingManufacturers = action.payload;
        });
        builder.addCase(getAlsoViewedProducts.pending, (state) => {
            state.gettingAlsoViewedProducts = true;
            state.errorGettingAlsoViewedProducts = null;
        })
        .addCase(getAlsoViewedProducts.fulfilled, (state, action) => {
            state.gettingAlsoViewedProducts = false;
            state.alsoViewedProducts = action.payload?.results;
        })
        .addCase(getAlsoViewedProducts.rejected, (state, action) => {
            state.gettingAlsoViewedProducts = false;
            state.errorGettingAlsoViewedProducts = action.payload;
        });
    }
});

export const { setPage } = temporarySlice.actions;
export const { setShowOfferConfirmation } = temporarySlice.actions;
export const { emptySearchResults } = temporarySlice.actions;

export default temporarySlice.reducer;