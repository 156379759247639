import Label from './Label';
import './style/Divider.css';
import React from 'react';

const Divider = ({ light = false, label, width, height, absolute, vertical = false, first = false, last = false, horizontalMobile = false, classes ='' }) => {
    return (
        <div className={`keystone-divider ${light ? 'light' : ''} ${!width && !vertical && "width-100"} ${horizontalMobile ? "horizontal-mobile" : ""} relative ${classes}`} style={{
            width: vertical === false ? "100%" : width && !vertical ? `${width}px` : "1px",
            position: absolute ? "absolute" : "relative",
            height: height ? height : vertical && !first && !last ? "100%" : vertical && first && !last ? "50%" : vertical && !first && last ? "50%" : "1px",
            left: vertical && "0px",
            top: vertical && !first && "0",
            bottom: vertical && !last && "0",
        }}>
            {label && <Label content={label} mono={true} uppercase={true} secondary={true} />}
        </div>
    );
};

export default Divider;