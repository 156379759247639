import './style/StoreContent.css';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Action from '../block/Action';
import Label from '../block/Label';
import Product from '../../page/Product';
import { getImgUrl } from '../../util/ImageSource';
import { formatToCurrency, formatPercentWithCommas, priceForStock, displayPriceForProduct, availableQuantityForProduct, quantityTypeForProduct, capitalizeFirstLetter, displayLotSizeForProduct } from '../../util/Calculate';
import Cart from './Cart';
import NoResultsIcon from '../../media/no-results@3x.png';
import ShieldIcon from '../../media/shield@3x.png';
import Close from '../../media/close@3x.png';
import ChevronDown from '../../media/chevron_down_24.png';
import CheckmarkCircleFill from '../../media/checkmark_circle_fill_24.png';
import AnalogDevices from '../../media/mfg-logos/analog-devices.png';
import Panasonic from '../../media/mfg-logos/panasonic.png';
import Mastech from '../../media/mfg-logos/mastech.png';
import KPS from '../../media/mfg-logos/kps.jpeg';
import Renesas from '../../media/mfg-logos/renesas.png';
import Onsemi from '../../media/mfg-logos/onsemi.png';
import Icon from '../block/Icon';
import Image from '../block/Image';
import Tile from '../block/Tile';
import ChipRounded from '../block/ChipRounded';
import PaginatedFooter from '../block/PaginatedFooter';

import { useSelector } from 'react-redux';
import { setPage } from '../../redux/temporarySlice';
import CartV2 from './Cart';
import Button from '../block/Button';
import { current } from '@reduxjs/toolkit';
import SimpleLoader from '../block/SimpleLoader';
import SiteFooter from '../block/SiteFooter';

const StoreContent = (
    { 
        showCart,
        cart,
        handleViewProduct,
        hrefForViewProduct,
        handleCategoryChangeNew,
        hrefForHandleCategoryChangeNew,
        pathBreadcrumbs,
        currentProduct,
        addToCart,
        setShowSuccessToast,
        alsoViewedProducts,
        showAddedToCartToast,
        setShowAddedToCartToast,
        categories,
        manufacturers
    }
) => {
    const rawProducts = useSelector((state) => state.temporary?.products);
    const loadingProducts = useSelector((state) => state.temporary?.gettingProducts);
    const { pathname, search } = useLocation();

    const analogDevices = "Analog Devices";
    const renesas = "Renesas";
    const kps = "KPS";
    const panasonic = "Panasonic";
    const mastech = "Mastech";
    const onsemi = "onsemi";
    const mfgLogos = [
        {
            manufacturer: onsemi,
            url: encodeURI(onsemi.toLowerCase()),
            image: Onsemi
        },
        {
            manufacturer: renesas,
            url: encodeURI(renesas.toLowerCase()),
            image: Renesas
        },
        {
            manufacturer: kps,
            url: encodeURI(kps.toLowerCase()),
            image: KPS
        },
        {
            manufacturer: panasonic,
            url: encodeURI(panasonic.toLowerCase()),
            image: Panasonic
        },
        {
            manufacturer: analogDevices,
            url: encodeURI(analogDevices.toLowerCase()),
            image: AnalogDevices
        },
        // {
        //     manufacturer: mastech,
        //     url: encodeURI(mastech.toLowerCase()),
        //     image: Mastech
        // }
    ];

    const navigate = useNavigate();

    const page = useSelector((state) => state.temporary?.page);
    const totalPages = useSelector((state) => state.temporary?.totalPages);
    const totalResults = useSelector((state) => state.temporary?.totalResults);

    const [isSearch, setIsSearch] = useState(false);
    const [categoriesVisible, setCategoriesVisible] = useState(false);
    const [manufacturersVisible, setManufacturersVisible] = useState(false);

    const currentPageDisplayName = useMemo(() => {
        const lastPath = pathname?.split("/")[pathname?.split("/")?.length - 1];
        if (lastPath === "") {
            return "All Products";
        } else if (isSearch) {
            return `${totalResults} Search Results`;
        } else if (pathname.includes("categories")) {
            return lastPath.replace("-", " ");
        } else if (pathname.includes("manufacturers")) {
            return manufacturers.find(manufacturer => decodeURIComponent(lastPath) === manufacturer.toLowerCase());
        } else if (pathname.includes("products")) {
            return decodeURIComponent(lastPath).toUpperCase();
        } else {
            return lastPath.replace("-", " ");
        }
    }, [isSearch, manufacturers, pathname, totalResults]);

    const sortedManufacturers = useMemo(() => {
        const editableManufacturers = [...manufacturers];
        return editableManufacturers?.sort((a, b) => {
            return a.localeCompare(b);
        });
    }, [manufacturers]);

    const categorySelectorRef = useRef(null);
    const categoryDropdownMenuRef = useRef(null);
    const manufacturerSelectorRef = useRef(null);
    const manufacturerDropdownMenuRef = useRef(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        if (searchParams?.has('query') && searchParams.get('query').length > 0) {
            setIsSearch(true);
        } else {
            setIsSearch(false);
        }
    }, [search]);

    const breadcrumbIsMPN = (breadcrumb) => {
        return breadcrumb?.replaceAll('-', ' ')?.toLowerCase() === currentProduct?.marketMpn?.toLowerCase()
            || decodeURIComponent(breadcrumb?.replaceAll('-', ' ')?.toLowerCase()) === currentProduct?.marketMpn?.toLowerCase()
            || breadcrumb?.toLowerCase() === currentProduct?.marketMpn?.toLowerCase();
    };


    const breadcrumbIsManufacturer = (breadcrumb) => {
        return !!manufacturers?.find(manufacturer => decodeURIComponent(breadcrumb)?.toLowerCase() === manufacturer.toLowerCase());
    };

    const handleViewAllProducts = () => {
        setCategoriesVisible(false);
        setManufacturersVisible(false);
        navigate("/");
    };

    const ManufacturerButton = ({ path = "/manufacturers", manufacturer }) => {

        const handleClick = () => {
            setManufacturersVisible(false);
            navigate(`${path}/${encodeURIComponent(manufacturer?.toLowerCase())}`);
        };
    
        return (
            <div className={`flex vertical`}>
                <div onClick={handleClick} className={`category-button flex horizontal align-center space-between h-gap-m`}>
                    <Label content={manufacturer} size="m" semiBold={true} displayFont={true} capitalize={true} />
                {pathname === (path + "/" + encodeURIComponent(manufacturer?.toLowerCase())) && (
                    <Icon
                        icon={CheckmarkCircleFill}
                        size={14}
                        color="var(--ks-positive-text)"
                        cursor="pointer" />
                )}
                </div>
            </div>
        );
    };

    const CategoryButton = ({ path = "/categories", category }) => {
        const [isExpanded, setIsExpanded] = useState(pathname.includes(path + "/" + category.name));
    
        const hasChildren = category.children && category.children.length > 0;
    
        const handleClick = () => {
            if (!hasChildren) {
                setCategoriesVisible(false);
                navigate(`${path}/${category.name}`);
            } else {
                setIsExpanded(!isExpanded);
            }
        };

        const handleViewAllOfCategory = () => {
            setCategoriesVisible(false);
            navigate(`${path}/${category.name}`);
        }
    
        return (
            <div className={`flex vertical ${isExpanded ? "category-section-expanded" : ""}`}>
                <div onClick={handleClick} className={`category-button flex horizontal align-center space-between h-gap-m ${isExpanded && hasChildren ? "category-button-expanded" : ""}`}>
                    <Label content={category.name.replaceAll("-", " ")} size="m" semiBold={true} displayFont={true} capitalize={true} />
                    {(hasChildren || (pathname === (path + "/" + category.name))) && (
                        <Icon
                            icon={hasChildren ? ChevronDown : CheckmarkCircleFill}
                            size={14}
                            rotate={(isExpanded && hasChildren) ? 180 : 0}
                            color={isExpanded && hasChildren ? "black" : !isExpanded ? "var(--ks-blue-dark)" : "var(--ks-positive-text)"}
                            cursor="pointer" />
                    )}
                </div>
                {isExpanded && hasChildren && (
                    <div className="pad-xs no-pad-right flex vertical v-gap-xs round-s">
                        <div onClick={handleViewAllOfCategory} className={`category-button flex horizontal align-center space-between h-gap-m`}>
                            <Label content={`All ${category.name.replaceAll("-", " ")}`} size="m" semiBold={true} displayFont={true} capitalize={true} />
                            {pathname === (path + "/" + category.name) && (
                                <Icon
                                    icon={CheckmarkCircleFill}
                                    size={14}
                                    color="var(--ks-positive-text)"
                                    cursor="pointer" />
                            )}
                        </div>
                        {category.children.map((child) => (
                            <CategoryButton key={child.name} category={child} path={path + "/" + category.name} />
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const CategorySelector = ({ ref }) => {
        return (
            <div
                ref={ref}
                className={`dropdown-button on-grey flex horizontal h-gap-s align-center ${pathname.includes("/categories") ? "active" : ""}`}
                onClick={() => setCategoriesVisible(!categoriesVisible)}>
                <Label displayFont={true} content="Categories" size="m" semiBold={true} />
                <Icon icon={ChevronDown} size={10} color="black" rotate={categoriesVisible ? 180 : 0} />
            </div>
        );
    }

    const ManufacturerSelector = ({ ref }) => {
        return (
            <div
                ref={ref}
                className={`dropdown-button on-grey flex horizontal h-gap-s align-center ${pathname.includes("/manufacturers") ? "active" : ""}`}
                onClick={() => setManufacturersVisible(!manufacturersVisible)}>
                <Label displayFont={true} content="Manufacturers" size="m" semiBold={true} />
                <Icon icon={ChevronDown} size={10} color="black" rotate={manufacturersVisible ? 180 : 0} />
            </div>
        );
    }

    // Handle click outside of dropdown menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (categoryDropdownMenuRef.current && !categoryDropdownMenuRef.current.contains(event.target) && categorySelectorRef?.current && !categorySelectorRef?.current?.contains(event.target)) {
                setCategoriesVisible(false);
            }

            if (manufacturerDropdownMenuRef.current && !manufacturerDropdownMenuRef.current.contains(event.target) && manufacturerSelectorRef?.current && !manufacturerSelectorRef?.current?.contains(event.target)) {
                setManufacturersVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex vertical mobile-width-100 flex-1 v-gap-m pad-m no-pad-bot no-pad-right no-pad-left store-content">
            {(!currentProduct && !showCart && pathname === "/" && !isSearch) && (
                <div className="flex vertical pad-m no-pad-top v-gap-m">
                    <Label header='h2' content="Featured Manufacturers" size="m" semiBold={true} displayFont={true} classes="pad-m no-pad-bot no-pad-left" />
                    <div className="flex horizontal flex-wrap gap-s">
                        {mfgLogos.map((manufacturer, index) => (
                            <Tile href={`/manufacturers/${manufacturer.url}`} classes="featured-manufacturer" onClick={() => navigate(`/manufacturers/${manufacturer.url}`)} key={index + manufacturer} index={index + manufacturer} content={(
                                <div className="flex vertical justify-center width-100 height-100 align-center">
                                    <img className="featured-manufacturer-image" src={manufacturer.image} alt={`${manufacturer} logo`} />
                                    <Label header='h3' content={manufacturer.manufacturer} classes='invisible'/>
                                </div>
                            )} />
                        ))}
                        <div id="verified-mfg" className="flex horizontal pad-l v-gap-xxs info-card align-center space-between">
                            <div className="flex vertical v-gap-xs">
                                <Label content="Authenticity Guarantee" displayFont={true} semiBold={true} size="m" header="h2"/>
                                <Label content="All stock is certified excess and ships directly from a verified manufacturer." displayFont={true} semiBold={true} size="s" line="s" secondary={true} header=""/>
                            </div>
                            <img alt='Authenticity Shield' src={ShieldIcon} style={{width: "32px", height: "32px"}} /> 
                        </div>
                    </div>
                </div>
            )}
            {!showCart && <div className="flex vertical space-between pad-m no-pad-bot gap-m relative">
                <div className={`flex horizontal flex-wrap gap-s`}>
                    {(pathname !== "/" && !isSearch) && <Action label="All Products" onClick={() => navigate('/')} href='/' breadcrumb={true} hideCaret={true} active={pathBreadcrumbs?.length === 0} />}
                    {pathBreadcrumbs?.map((breadcrumb, index) => {
                        if (index !== pathBreadcrumbs.length - 1) {
                            return (
                                <Action key={breadcrumb} index={index} label={breadcrumbIsManufacturer(breadcrumb) ? manufacturers?.find(manufacturer => decodeURIComponent(breadcrumb)?.toLowerCase() === manufacturer.toLowerCase()) : breadcrumbIsMPN(breadcrumb) ? breadcrumb.replaceAll("-", " ")?.toUpperCase() : breadcrumb.replaceAll("-", " ")} onClick={() => breadcrumbIsMPN(breadcrumb) ? handleViewProduct(currentProduct) : handleCategoryChangeNew(breadcrumb)} breadcrumb={true} active={index === pathBreadcrumbs.length - 1} href={breadcrumbIsMPN(breadcrumb) ? hrefForViewProduct(currentProduct) : hrefForHandleCategoryChangeNew(breadcrumb)} />
                            );
                        }
                    })}
                </div>
                <div className="flex horizontal mobile-vertical align-center space-between gap-s">
                    {!currentProduct && <Label content={currentPageDisplayName} size="l" semiBold={true} displayFont={true} capitalize={true} />}
                    {!currentProduct && (
                        <div className="flex horizontal h-gap-s">
                            <div className="flex horizontal h-gap-s">
                                {CategorySelector({ ref: categorySelectorRef })}
                            </div>
                            <div ref={categoryDropdownMenuRef} className={`dropdown-menu flex vertical round-m ${categoriesVisible ? "show" : ""}`}>
                                <div className="dropdown-menu-header flex desktop-hide-small">
                                    <div className="flex width-100 horizontal align-center space-between">
                                        <Label displayFont={true} content="Categories" size="l" semiBold={true} color="white" />
                                        <Button classes="dropdown-menu-close" icon={Close} iconColor="white" iconSize={16} onClick={() => setCategoriesVisible(false)} />
                                    </div>
                                </div>
                                <div className="categories-section flex vertical pad-xs v-gap-xs height-100 overflow-y-scroll">
                                    <div onClick={handleViewAllProducts} className={`category-button flex horizontal align-center space-between h-gap-m`}>
                                        <Label content="All Products" size="m" semiBold={true} displayFont={true} capitalize={true} />
                                        {pathname === "/" && <Icon icon={CheckmarkCircleFill} size={14} color="var(--ks-positive-text)" cursor="pointer"/>}
                                    </div>
                                    {categories?.map((category) => (
                                        <CategoryButton key={category.name} category={category} />
                                    ))}
                                </div>
                            </div>
                            <div className="flex horizontal h-gap-s">
                                {ManufacturerSelector({ ref: manufacturerSelectorRef })}
                            </div>
                            <div ref={manufacturerDropdownMenuRef} className={`dropdown-menu flex vertical round-m ${manufacturersVisible ? "show" : ""}`}>
                                <div className="dropdown-menu-header flex desktop-hide-small">
                                    <div className="flex width-100 horizontal align-center space-between">
                                        <Label displayFont={true} content="Manufacturers" size="l" semiBold={true} color="white" />
                                        <Button classes="dropdown-menu-close" icon={Close} iconColor="white" iconSize={16} onClick={() => setManufacturersVisible(false)} />
                                    </div>
                                </div>
                                <div className="categories-section flex vertical pad-xs v-gap-xs height-100 overflow-y-scroll">
                                    <div onClick={handleViewAllProducts} className={`category-button flex horizontal align-center space-between h-gap-m`}>
                                        <Label content="All Manufacturers" size="m" semiBold={true} displayFont={true} capitalize={true} />
                                        {pathname === "/" && <Icon icon={CheckmarkCircleFill} size={14} color="var(--ks-positive-text)" cursor="pointer"/>}
                                    </div>
                                    {sortedManufacturers?.map((manufacturer) => (
                                        <ManufacturerButton key={manufacturer} manufacturer={manufacturer} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>}
            <div style={{flexBasis: "1px", flexGrow: "1"}}>
                <div className={`${(!showCart && !currentProduct) ? "grid" : "flex-vertical flex-1 h-gap-xs"} pad-m no-pad-top ${loadingProducts ? "relative" : ""}`}>
                    {showCart ? (
                        <Cart items={cart} handleViewProduct={handleViewProduct} hrefForViewProduct={hrefForViewProduct} setShowSuccessToast={setShowSuccessToast} />
                    ) : currentProduct ? (
                        <Product currentProduct={currentProduct} addToCart={addToCart} alsoViewedProducts={alsoViewedProducts} cart={cart} handleViewProduct={handleViewProduct} hrefForViewProduct={hrefForViewProduct} showAddedToCartToast={showAddedToCartToast} setShowAddedToCartToast={setShowAddedToCartToast} />
                    ) : rawProducts?.length > 0 && rawProducts?.map((product, index) => (
                        <Tile
                            key={index + product.marketMpn}
                            index={index + product.marketMpn}
                            href={hrefForViewProduct(product)}
                            onClick={() => handleViewProduct(product)}
                            classes={`${loadingProducts ? "fade" : ""}`}
                            content={
                                <div className="flex vertical pad-m height-100 space-between">
                                    <Label classes='invisible' header='h3' content=
                                        {`${product.marketManufacturer} ${product.marketMpn}`}
                                    />
                                    <div className="flex vertical">
                                        <Label content={product.marketManufacturer || ""} size="s" semiBold={true} mono={true} displayFont={true} secondary={true} />
                                        <Label content={product.marketMpn} size="m" mono={true} />
                                    </div>
                                    <Image source={getImgUrl(product)} altTag={product.marketMpn} fullWidth={true} />
                                    <div className="flex vertical">
                                        <Label content={`${availableQuantityForProduct(product)} ${capitalizeFirstLetter(quantityTypeForProduct(product))} ${displayLotSizeForProduct(product)}`} size="s" semiBold={true} mono={true} displayFont={true} secondary={true} />
                                        <Label content={displayPriceForProduct(product) === 0 ? "N/A" : formatToCurrency(displayPriceForProduct(product))} size="m" mono={true} />
                                    </div>
                                </div>
                            } />
                    ))}
                    {(!showCart && !currentProduct) && <SimpleLoader loading={loadingProducts} />}
                </div>
                {(!showCart && !currentProduct && rawProducts?.length === 0) && (
                    <div className="flex width-100 vertical align-center pad-xl v-gap-s">
                        <Icon icon={NoResultsIcon} size={48} color="#6f7aa5" />
                        <Label header="h2" content="No products found matching that search" size="m" semiBold={true} displayFont={true} center={true} secondary={true} />
                    </div>
                )}
            </div>
            {(!showCart && !currentProduct) && <div className="flex horizontal pad-l no-pad-top justify-center">
                <PaginatedFooter page={page} setPage={setPage} totalPages={totalPages} totalResults={totalResults} />
            </div>}
            <SiteFooter />
        </div>
    );
};

export default StoreContent;