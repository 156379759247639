import './style/Label.css';
import React from 'react';
import { } from '../../redux/localSlice';

const Label = ({
    index,
    customRef = null,
    customId = null,
    content = "",
    size = "m",
    line,
    color= "black",
    bold = false,
    mono = false,
    secondary = false,
    onBrand = false,
    maxContent = false,
    uppercase = false,
    invert = false,
    center = false,
    style = {},
    displayFont = false,
    positive = false,
    negative = false,
    right = false,
    hide = false,
    singleLine = false,
    capitalize = false,
    lazy = false,
    pad = null,
    onClick = null,
    flex = null,
    hideMobile = false,
    shadow = false,
    classes = "",
    header = "",
    doNotBreak = false,
    inline = false,
    semiBold = false
}) => {
    const ref = customRef ? customRef : undefined;
    const id = customId ? customId : undefined;
    const idx = index ? index : null;
    const className = `keystone-label ${doNotBreak ? "do-not-break" : ""} ${inline ? "inline" : ""} ${!displayFont ? "sf-pro" : ""} size-${size} ${bold ? "bold" : semiBold ? "semi-bold" : "medium"} ${line ? "line-" + line : ""} ${mono ? "sf-mono" : ""} ${shadow ? "shadow" : ""}  ${secondary ? "secondary" : ""} ${onBrand ? "on-brand" : ""} ${maxContent ? "max-content" : ""} ${uppercase ? "uppercase" : ""} ${invert ? "invert" : ""} ${center ? "center-text" : ""} ${positive ? "positive" : ""} ${negative ? "negative" : ""} ${right ? "right-align justify-end" : ""} ${color === "brand" ? "brand-color" : color ==="white" ? "white" : color}  ${hide ? "hide" : ""} ${singleLine ? "single-line" : ""} ${capitalize ? "capitalize" : ""} ${lazy ? "lazy" : ""} ${onClick ? "label-clickable" : ""} ${flex ? `flex-${flex}` : ""} ${hideMobile ? "mobile-hide" : ""} ${pad ? `pad-${pad}` : ""} ${classes}`;

    return header === "h1" ? (
        <h1 ref={ref} id={id} index={idx} onClick={onClick} className={className} style={style}>
            {content ? content : ""}
        </h1>
    ) 
    : header === "h2" ? (
        <h2 ref={ref} id={id} index={idx} onClick={onClick} className={className} style={style}>
            {content ? content : ""}
        </h2>)
    : header === "h3" ? (
        <h3 ref={ref} id={id} index={idx} onClick={onClick} className={className} style={style}>
            {content ? content : ""}
        </h3>)
    : header === "h4" ? (
        <h4 ref={ref} id={id} index={idx} onClick={onClick} className={className} style={style}>
            {content ? content : ""}
        </h4>)
    : (
        <p ref={ref} id={id} index={idx} onClick={onClick} className={className} style={style}>
            {content ? content : ""}
        </p>
    );
};

export default Label;