import { useState, useEffect } from 'react';

function useDimensionObserver(ref) {
    const [dimensions, setDimensions] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (ref.current) {
                setDimensions(ref.current.getBoundingClientRect());
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        // Initial check
        handleResize();

        // Cleanup
        return () => {
            resizeObserver.disconnect();
        };
    }, [ref]);

    return dimensions;
}

export default useDimensionObserver;