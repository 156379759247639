import { useState, useEffect } from 'react';

function useHoverObserver(ref) {
    const [isHovered, setIsHovered] = useState(false);


    useEffect(() => {
        const element = ref.current;

        const handleMouseEnter = () => setIsHovered(true);
        const handleMouseLeave = () => setIsHovered(false);

        if (element) {
            element.addEventListener('mouseenter', handleMouseEnter);
            element.addEventListener('mouseleave', handleMouseLeave);
        }

        // Cleanup listeners on unmount or when element changes
        return () => {
            if (element) {
                element.removeEventListener('mouseenter', handleMouseEnter);
                element.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, [ref]);


    return isHovered;
}

export default useHoverObserver;