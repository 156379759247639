import './style/StockRow.css';
import React, { useEffect, useRef } from 'react';
import Label from './Label';
import { formatNumberWithCommas, formatToCurrency, quantityTypeSingularForStock, quantityTypeForStock, availableQuantityForStock } from '../../util/Calculate';
import Icon from './Icon';
import { Tooltip } from '@visx/tooltip';
import ChipRounded from './ChipRounded';
import Input from './Input';

import China from '../../media/china.png';
import Usa from '../../media/usa.png';
import TruckFill from '../../media/truck_fill_24.png';
import Action from './Action';
import useResponsiveBreakpoint from '../../hooks/ResponseBreakpoint';

const StockRow = ({ index, stock, handleOfferQuantityChange, handleOfferPriceChange, forceCard = null, cardStyleBreakpoint = 532, wrappedLayoutCallback = () => {}, classes = ""}) => {
    const containerRef = useRef(null);
    const usingWrappedLayout = useResponsiveBreakpoint(containerRef, cardStyleBreakpoint, 'width');

    const quantityType = quantityTypeSingularForStock(stock);

    useEffect(() => {
        if (forceCard !== null) {
            wrappedLayoutCallback(forceCard);
        } else {
            wrappedLayoutCallback(usingWrappedLayout);
        }
    }, [forceCard, usingWrappedLayout, wrappedLayoutCallback]);

    return (
        <div key={"stock" + index} ref={containerRef} className={`flex horizontal pad-m h-gap-m align-center stock-row-v2 ${((usingWrappedLayout && forceCard === null) || forceCard) ? 'card-style': ''} ${classes}`}>
            <div className='flex v-gap-xs ship-from-location-container'>
                <Label classes='ship-from-country-text' content={"Ships from"} size="s" line="s" displayFont={true} semiBold={true} secondary={true} />
                <div className='flex horizontal align-center h-gap-xs'>
                    <img
                        className='country-flag-img'
                        src={stock.country === "China" ? China : Usa}
                        alt={stock.country} />
                    <Label classes='ship-from-country-text' content={stock.country === "China" ? "CHN":"USA"} size="m" line="s" displayFont={true} semiBold={true} />
                </div>
            </div>
            <div className='flex v-gap-xs lead-time-container'>
                <Label classes='lead-time-text' content={"Ships in"} size="s" line="s" displayFont={true} semiBold={true} secondary={true} />
                <div className='flex horizontal align-center h-gap-xs'>
                    <Icon icon={TruckFill} size={16} color="var(--ks-blue-dark)" />
                    <Label classes='ship-from-country-text' content={"5-7 Days"} size="m" line="s" displayFont={true} semiBold={true} />
                </div>
            </div>
            <div className='flex available-quantity-container'>
                <Label classes='available-quantity-header-title' content={"Available Quantity"} size="s" line="s" displayFont={true} semiBold={true} secondary={true} />
                <div className="flex flex-wrap horizontal h-gap-xs align-end">
                    <Label content={formatNumberWithCommas(availableQuantityForStock(stock))} size="xl" displayFont={true} semiBold={true} />
                    <Label content={quantityTypeForStock(stock)} size="s" line="s" displayFont={true} semiBold={true} />
                </div>
                <Label classes='available-quantity-country-subtitle' content={stock.country === "China" ? "CHN":"USA"} size="s" line="s" displayFont={true} semiBold={true} secondary={true} />
            </div>
            <div className="flex approved-price-container">
                <Label classes='approved-price-header-title' content={"Approved Price"} size="s" line="s" displayFont={true} semiBold={true} secondary={true} />
                <div className="flex horizontal h-gap-s">
                    <div className="flex flex-wrap horizontal h-gap-xs align-end">
                        <Label
                            content={stock?.approvedPrice === 0 ? "N/A" : formatToCurrency(stock?.approvedPrice)}
                            size="xl"
                            displayFont={true}
                            semiBold={true}
                            secondary={stock?.approvedPrice === 0}
                            style={stock?.discount > 0 ? {color: "var(--ks-positive-text)"} : {}}
                        />
                        {stock?.approvedPrice !== 0 && <Label
                            content={`/${quantityTypeSingularForStock(stock)}` + (stock?.lot_size > 1 ? ` of ${stock?.lot_size}` : "")}
                            size="s"
                            line="s"
                            displayFont={true}
                            semiBold={true}
                            style={stock?.discount > 0 ? {color: "var(--ks-positive-text)"} : {}}
                        />}
                    </div>
                    {stock?.discount > 0 && (
                        <div className="border-100 tag-wrapper tooltip-wrapper pointer relative" style={{padding: "5px"}}>
                            <Icon cursor="pointer" classes="tag" size={10} color="var(--ks-positive-text)" />
                            <Tooltip top={10} left={-210} className="flex vertical v-gap-xs">
                                <div className="flex horizontal align-center h-gap-m space-between">
                                    <Label content="Volume Discount" size="s" line="s" displayFont={true} semiBold={true} />
                                    <ChipRounded label={`${stock.discount}%`} positive={true} small={true} displayFont={true} /> 
                                </div>
                                {stock.discount === 20 && <Label content="Maximum discount applied." size="s" line="s" secondary={true} displayFont={true} semiBold={true} />}
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div className="flex h-gap-xs approved-price-subtitle-container ">
                    <Icon icon={TruckFill} size={12} color="var(--ks-blue-dark)" />
                    <Label content="5-7 Days" size="s" line="s" displayFont={true} semiBold={true} secondary={true} />
                </div>
            </div>
            <div className="flex v-gap-xs offer-quantity-container">
                <Label classes='offer-quantity-input-header' content="Offer Quantity" size="s" line="s" displayFont={true} semiBold={true} secondary={true} />
                <div className="flex vertical relative">
                    <Input
                        value={stock.offerQuantity.toString()}
                        onChange={(e) => handleOfferQuantityChange(e, index)}
                        width100={true}
                        type="number"
                        classes={`${stock.offerQuantity === 0 ? "" : (stock.offerPrice * stock.offerQuantity) < stock?.boxMinimum ? "warning" : "success"}`}
                        style={{
                            textAlign: "right",
                            paddingLeft: '32px'
                        }}
                    />
                    {stock.offerQuantity !== 0 && (
                        <Icon
                            size={16}
                            cursor="pointer"
                            classes={`tooltip-wrapper ${(stock.offerPrice * stock.offerQuantity) < stock?.boxMinimum ? "info-circle" : "checkmark-circle"}`}
                            color={`${(stock.offerPrice * stock.offerQuantity) < stock?.boxMinimum ? "var(--warning-text)" : "var(--ks-positive-text)"}`}
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)"
                            }}
                        >
                        </Icon>
                    )}
                    {stock.offerQuantity !== 0 && (
                        <Tooltip top={16} left={16} className="flex vertical v-gap-xs">
                            <div className="flex horizontal align-center h-gap-m space-between">
                                <Label
                                    content={`${(stock.offerPrice * stock.offerQuantity) < stock?.boxMinimum ? "Low Offer Quantity" : "Approved Offer Value"}`}
                                    size="s"
                                    line="s"
                                    displayFont={true}
                                    semiBold={true} />
                            </div>
                            <Label
                                content={(stock.offerPrice * stock.offerQuantity) < stock?.boxMinimum ?
                                    `Storing facility preferences offer values above ${formatToCurrency(stock?.boxMinimum)}.` :
                                    `Offer value of ${formatToCurrency(stock.offerPrice * stock.offerQuantity)} meets preference of storing facility.`}
                                size="s"
                                line="s"
                                secondary={true}
                                displayFont={true}
                                semiBold={true} />
                            {(((stock.offerPrice * stock.offerQuantity) < stock?.boxMinimum) && stock?.offerPrice !== 0) && (
                                <ChipRounded
                                label={`Your offer of ${formatToCurrency(stock.offerPrice * stock.offerQuantity)} may still be accepted.`}
                                positive={true}
                                uppercase={false}
                                warning={true}
                                displayFont={true} />
                            )}
                        </Tooltip>
                    )}
                </div>
                <Action
                    label={stock.quantity === stock.offerQuantity ? "Remove All" : "Add All"}
                    onClick={() => handleOfferQuantityChange("all", index)}
                    classes="underline offer-quantity-add-all" />
            </div>
            <div className="flex flex-grow v-gap-xs offer-price-container">
                <Label classes='offer-price-input-header' content="Offer Price" size="s" line="s" displayFont={true} semiBold={true} secondary={true} />
                <div className="flex vertical relative">
                    <Input
                        value={stock.offerPrice?.toString()}
                        onChange={(e) => handleOfferPriceChange(e, index)}
                        width100={true}
                        type="number"
                        classes={`${(stock.offerQuantity === 0 || stock?.offerPrice === 0 || stock?.approvedPrice === 0) ? "" : stock.offerPrice < stock?.approvedPrice ? "warning" : "success"}`}
                        style={{
                            textAlign: "right",
                            paddingLeft: '32px'
                        }}
                    />
                    {(stock?.approvedPrice !== 0 && stock.offerQuantity !== 0 && stock?.offerPrice !== 0) && <Icon
                        size={16}
                        cursor="pointer"
                        classes={`tooltip-wrapper ${stock.offerPrice < stock?.approvedPrice ? "info-circle" : "checkmark-circle"}`}
                        color={`${stock.offerPrice < stock?.approvedPrice ? "var(--warning-text)" : "var(--ks-positive-text)"}`}
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "8px",
                            transform: "translateY(-50%)"
                        }}
                    />}
                    <Tooltip top={16} left={-200} className="flex vertical v-gap-xs">
                        <div className="flex horizontal align-center h-gap-m space-between">
                            <Label
                                content={`${stock.offerPrice < stock?.approvedPrice? "Low Offer Price" : "Approved Offer Price"}`}
                                size="s"
                                line="s"
                                displayFont={true}
                                semiBold={true} />
                        </div>
                        <Label
                            content={stock.offerPrice < stock?.approvedPrice ?
                                `Offer price is below approved price per ${quantityType}.` :
                                `Storing facility has approved this discounted price per ${quantityType}.`}
                            size="s"
                            line="s"
                            secondary={true}
                            displayFont={true}
                            semiBold={true} />
                        {((stock?.offerPrice < stock?.approvedPrice) || (stock?.offerQuantity < stock?.quantity)) && <ChipRounded
                            label={(stock?.offerPrice < stock?.approvedPrice) ? `Your offer of ${formatToCurrency(stock?.offerPrice)} per ${quantityType} may still be accepted.` : "Higher offer quantities apply better discounts."}
                            uppercase={false}
                            warning={(stock?.offerPrice < stock?.approvedPrice) ? true : false}
                            displayFont={true} />}
                    </Tooltip>
                </div>
                <Label classes='offer-price-unit-subtitle' content={`$/${quantityType}`} size="s" displayFont={true} semiBold={true} secondary={true} />
            </div>
        </div>
    );
};

export default StockRow;