import './style/PaginatedFooter.css';
import React from 'react';
import Action from './Action'; // Make sure to import your existing Action component
import { getProducts } from '../../redux/temporarySlice';

import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setScrollPosition } from '../../redux/localSlice';

const PaginatedFooter = ({ page, setPage, totalPages, totalResults }) => {
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const MAX_BUTTONS = 10;
    const pages = [];

    let startPage = Math.max(page - Math.floor(MAX_BUTTONS / 2), 1);
    let endPage = startPage + MAX_BUTTONS - 1;

    // Adjust startPage and endPage if they exceed boundaries
    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(endPage - MAX_BUTTONS + 1, 1);
    }

    // Generate pages array
    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }

    const hrefForPageChange = (newPage) => {
        // navigate to current path with new page
        const searchParams = new URLSearchParams(search);
        searchParams.set('page', newPage + 1);
        return `${pathname}?${searchParams.toString()}`;
    }
    const handlePageChange = (newPage) => {
        dispatch(setScrollPosition(0));
        navigate(hrefForPageChange(newPage));
    };

    return (
        <div className="flex horizontal paginated-footer">
            {pages.map(num => (
                <Action
                    key={num}
                    label={`${num}`}
                    onClick={() => handlePageChange(num - 1)}
                    href={hrefForPageChange(num - 1)}
                    hideCaret={true}
                    active={num === Number(page) + 1}
                    classes={num === Number(page) + 1 ? "shadow" : ""}
                />
            ))}
        </div>
    );
};

export default PaginatedFooter;