import './style/Icon.css';
import React from 'react';

const Icon = ({ customId, icon, color = 'black', size = '24', hoverColor, stylisticFill = false, mask = true, z=1, handleMouseEnter, handleMouseLeave, invert, rotate = null, cursor = null, style = {}, classes = "", showHoverColor = false, children, onClick = null }) => {
    const getStyle = () => {
        let newStyle = {
            backgroundColor: showHoverColor ? hoverColor : color,
            width: `${size}px`,
            height: `${size}px`,
            minWidth: `${size}px`,
            minHeight: `${size}px`,
            zIndex: z,
            filter: invert ? 'invert(1)' : 'none',
            transform: rotate ? `rotate(${rotate}deg)` : 'none',
            ...style,
        };

        if (icon) {
            newStyle = {
                ...newStyle,
                maskImage: `url(${icon})`,
                WebkitMaskImage: `url(${icon})`,
            };
        }

        if (!mask) {
            newStyle = {
                ...newStyle,
                maskImage: 'none',
                WebkitMaskImage: 'none',
                backgroundImage: `url(${icon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: 'transparent',
            };
        }

        return newStyle;
    }
    return (
        <div 
            id={customId && customId}
            className={`keystone-icon-wrapper ${onClick ? "clickable" : ""} ${classes}`}
            style={getStyle()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default Icon;