import './style/StoreHeader.css';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import searchIcon from '../../media/search.png';
import Label from '../block/Label';
import Icon from '../block/Icon';
import Input from '../block/Input';
import Cart from '../../media/cart@3x.png';
import Divider from '../block/Divider';
import Action from '../block/Action';
import { getImgUrl } from '../../util/ImageSource';
import Tile from '../block/Tile';
import Image from '../block/Image';
import { formatToCurrency, displayPriceForProduct, availableQuantityForProduct, quantityTypeForProduct, capitalizeFirstLetter, displayLotSizeForProduct } from '../../util/Calculate';
import { setScrollPosition } from '../../redux/localSlice';
import { getProducts, emptySearchResults } from '../../redux/temporarySlice';
import Wordmark from '../block/Wordmark';
import SimpleLoader from '../block/SimpleLoader';
import LinkButtonWrapper from '../block/LinkButtonWrapper';
import WordmarkWhite from '../../media/materialize-logo-wordmark-white.png';

const StoreHeader = ({ searchInputValue, setSearchInputValue, cart, showCart, handleViewProduct, hrefForViewProduct }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [resultsVisible, setResultsVisible] = useState(false);
    const inputRef = useRef(null);
    const desktopResultsWrapperRef = useRef(null);
    const resultsWrapperRef = useRef(null);

    const searching = useSelector((state) => state.temporary?.gettingProducts);
    const searchResults = useSelector((state) => state.temporary?.searchResults);

    const handleSearchInputChange = (e) => {
        setSearchInputValue(e.target.value);
    };

    const handleShowCart = () => {
        dispatch(setScrollPosition(0));
        navigate('/cart');
    };

    useEffect(() => {
        if (searchInputValue?.length > 0) {
            setResultsVisible(true);
        }
    }, [searchInputValue]);

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {;
            if (searchInputValue?.length > 0) {
                dispatch(getProducts({
                    query: encodeURIComponent(searchInputValue),
                    category: "",
                    manufacturer: "",
                    page: 0,
                    limit: 5
                }));
            } else {
                dispatch(emptySearchResults());
            }
        }, 500);
        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [dispatch, searchInputValue]);

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        setResultsVisible(false);
        setSearchInputValue('');
        navigate(`/search?query=${searchInputValue}`);
    };

    const handleInputFocus = () => {
        console.log('Input focused');
        setResultsVisible(true);
    };

    // Listen for clicks outside the component
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                resultsWrapperRef.current &&
                !resultsWrapperRef.current.contains(event.target) &&
                desktopResultsWrapperRef.current &&
                !desktopResultsWrapperRef.current.contains(event.target) &&
                inputRef.current &&
                !inputRef.current.contains(event.target)
            ) {
                setResultsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const viewProduct = (product) => {
        handleViewProduct(product);
        setResultsVisible(false);
        setSearchInputValue('');
    }

    const handleGoHome = () => {
        dispatch(setScrollPosition(0));
        navigate('/');
    };

    const handleOpenAboutUs = () => {
        window.open("https://www.trymaterialize.com/", "_blank");
    };

    const handleOpenTermsOfUse = () => {
        window.open("https://www.trymaterialize.com/terms-of-use", "_blank");
    };

    const handleOpenPrivacyPolicy = () => {
        window.open("https://www.trymaterialize.com/privacy-policy", "_blank");
    };

    const searchTemplate = (classes) => {
        return (
            <div id="product-search-wrapper" className={`relative ${searchResults?.length > 0 ? "results" : ""} ${classes}`}>
                <Icon customId="product-search-icon" icon={searchIcon} color="white" size="24" />
                <Input customId="product-search-input" inputRef={inputRef} onKeyDown={handleEnterKeyPress} onChange={handleSearchInputChange} value={searchInputValue} placeholder="Search" flex={true} onBrand={true} onFocus={handleInputFocus} />
                {(resultsVisible && searchInputValue?.length > 0) && (
                    <div id="product-search-results-wrapper" ref={classes === "mobile-hide" ? desktopResultsWrapperRef : resultsWrapperRef} className="flex vertical keystone-card relative">
                        {(searchInputValue?.length > 0 && searchResults?.length > 0) && (
                            <div className="flex vertical">
                                <div className="flex vertical align-center pad-xs v-gap-s">
                                    <Action href={`/search?query=${searchInputValue}`} label={`View all products matching '${searchInputValue}'`} onClick={handleSearch} flex={true} />
                                </div>
                                <Divider vertical={false} />
                            </div>
                        )}
                        {searchResults?.length > 0 ? searchResults?.map((product, index) => (
                            <Tile
                                key={index + product.marketMpn}
                                index={index + product.marketMpn}
                                onClick={() => viewProduct(product)}
                                href={hrefForViewProduct(product)}
                                rowStyle={true}
                                content={
                                    <div className="flex horizontal height-100 pad-xs align-center no-pointer">
                                        <Image source={getImgUrl(product)} altTag={product.marketMpn} width="small" />
                                        <div className="flex horizontal flex-1 space-between">
                                        <div className="flex vertical pad-m">
                                            <Label content={product.marketManufacturer || ""} size="s" semiBold={true} mono={true} displayFont={true} secondary={true} />
                                            <Label content={product.marketMpn} size="m" mono={true} />
                                        </div>
                                        <div className="flex vertical pad-m align-end">
                                            <Label content={`${availableQuantityForProduct(product)} ${capitalizeFirstLetter(quantityTypeForProduct(product))} ${displayLotSizeForProduct(product)}`} size="s" semiBold={true} mono={true} displayFont={true} secondary={true} />
                                            <Label content={displayPriceForProduct(product) === 0 ? "N/A" : formatToCurrency(displayPriceForProduct(product))} size="m" mono={true} />
                                        </div>
                                        </div>
                                    </div>
                                }
                            />
                        )) : !searching && (
                            <div className="flex vertical align-center pad-s v-gap-s">
                                <Label content={`No products found matching "${searchInputValue}"`} size="s" semiBold={true} displayFont={true} center={true} secondary={true} />
                            </div>
                        )}
                        <SimpleLoader loading={searching} classes={`${searching ? "fade-in" : ""}`} />
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className="shop-max-width flex vertical pad-m no-pad-top no-pad-bot width-100">
            <div className="flex horizontal space-between pad-m h-gap-xl black-bg">
                <div className="flex horizontal h-gap-xl">
                    <LinkButtonWrapper label="About Us" href="https://www.trymaterialize.com/">
                        <Label content="About Us" color="white-transparent" mono={true} semiBold={true} size="s" uppercase={true} onClick={handleOpenAboutUs} />
                    </LinkButtonWrapper>
                    <LinkButtonWrapper label="Terms of Use" href="https://www.trymaterialize.com/terms-of-use">
                        <Label content="Terms of Use" color="white-transparent" mono={true} semiBold={true} size="s" uppercase={true} classes="mobile-hide" onClick={handleOpenTermsOfUse} />
                    </LinkButtonWrapper>
                    <LinkButtonWrapper label="Privacy Policy" href="https://www.trymaterialize.com/privacy-policy">
                        <Label content="Privacy Policy" color="white-transparent" mono={true} semiBold={true} size="s" uppercase={true} classes="mobile-hide" onClick={handleOpenPrivacyPolicy} />
                    </LinkButtonWrapper>
                </div>
                <a href="mailto:orders@trymaterialize.com" className="yellow size-s sf-mono uppercase bold no-decoration" target="_blank" rel="noreferrer">orders@trymaterialize.com</a>
            </div>
            <div id="store-header" className="flex horizontal width-100 space-between align-center pad-s h-gap-m">
                <div className="vertical pad-m no-pad-bot no-pad-top no-pad-right flex height-100 relative justify-center">
                    <a href="/" className="flex"><img src={WordmarkWhite} alt="Materialize Wordmark" className="wordmark-image" /></a>
                </div>
                {searchTemplate("mobile-hide")}
                <div className="vertical flex height-100 pad-xs relative justify-center">
                    <LinkButtonWrapper label="Cart" href="/cart">
                        <button id="cart-button" className={`h-gap-s relative ${showCart ? "active" : ""}`} onClick={handleShowCart}>
                            <Label content="Cart" color="white" mono={true} semiBold={true} size="s" uppercase={true} />
                            {cart?.length > 0 && <div id="cart-count" className="flex align-center justify-center"><Label content={cart.length} mono={true} size="xs" semiBold={true} /></div>}
                            <Icon id="cart-logo" icon={Cart} color='white' size={22} alt="Cart Icon" />
                        </button>
                    </LinkButtonWrapper>
                </div>
            </div>
            <div id="store-header-footer" className="flex horizontal space-between pad-s no-pad-top h-gap-xl black-bg desktop-hide">
                {searchTemplate()}
            </div>
        </div>
    );
};

export default StoreHeader;